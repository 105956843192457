import React, { useState, useRef, useEffect } from "react";
import { Modal, Button, Select, Space, Image, Upload, message, Menu } from "antd";
import { CameraOutlined, ReloadOutlined, UploadOutlined } from "@ant-design/icons";
import Webcam from "react-webcam";
import { RcFile } from "antd/lib/upload/interface";

const { Option } = Select;

interface ImageUploadModalProps {
  visible: boolean;
  onClose: () => void;
  onSubmit: (imageBuffer: string | null, fileName?: string | null) => void;
  modalLoading: boolean;
}

const AddOrEditMemberImageModal: React.FC<ImageUploadModalProps> = ({ visible, onClose, onSubmit, modalLoading }) => {
  const [imageBuffer, setImageBuffer] = useState<string | null>(null);
  const [fileType, setFileType] = useState<string | null>(null);
  const [webcams, setWebcams] = useState<MediaDeviceInfo[]>([]);
  const [selectedWebcam, setSelectedWebcam] = useState<number | null>(null);
  const [isPreviewMode, setIsPreviewMode] = useState(false); // New state to control preview mode
  const [activeOption, setActiveOption] = useState<"upload" | "webcam">("upload"); // State to track active option
  const webcamRef = useRef<Webcam>(null);

  useEffect(() => {
    const fetchWebcams = async () => {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const webcamDevices = devices.filter((device) => device.kind === "videoinput");
        setWebcams(webcamDevices);
        if (webcamDevices.length > 0) {
          setSelectedWebcam(0);
        }
      } catch (error) {
        console.error("Error fetching webcam devices:", error);
      }
    };

    if (visible) {
      fetchWebcams();
    }
  }, [visible]);

  const handleImageCapture = () => {
    const imageSrc = webcamRef.current?.getScreenshot();
    const buffer = imageSrc?.split(",")[1] || null;
    setImageBuffer(buffer);
    setIsPreviewMode(true); // Enable preview mode after capturing image
  };

  const handleWebcamRetry = () => {
    setImageBuffer(null);
    setIsPreviewMode(false); // Disable preview mode on retry
  };

  const WebcamButtons: any = () => {
    return (
      <Space style={{ display: "flex", justifyContent: "center", marginTop: "10px" }} size={15}>
        <Button
          type="primary"
          onClick={handleImageCapture}
          icon={<CameraOutlined />}
          style={{ width: 48, height: 48, borderRadius: "50%" }}
          size="large"
          disabled={selectedWebcam === null}
        />
        <Button
          type="default"
          onClick={handleWebcamRetry}
          icon={<ReloadOutlined />}
          style={{ width: 48, height: 48, borderRadius: "50%" }}
          size="large"
        />
      </Space>
    );
  };

  const modalFooter = (
    <Space size={10}>
      <Button
        type="primary"
        onClick={() => onSubmit(imageBuffer, fileType)}
        loading={modalLoading}
        disabled={!imageBuffer} // Disable Submit button when there's no captured image
      >
        Upload
      </Button>
      <Button type="default" onClick={onClose}>
        Cancel
      </Button>
    </Space>
  );

  const handleWebcamChange = (value: number) => {
    setSelectedWebcam(value);
  };

  const handleOptionChange = (option: "upload" | "webcam") => {
    setActiveOption(option);
    if (option === "webcam" && selectedWebcam === null && webcams.length > 0) {
      setSelectedWebcam(0);
    }
  };

  const handleUpload = (file: RcFile) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      if (reader.result) {
        // Check if reader.result is defined
        const base64String = reader.result.toString().split(",")[1];
        // Preserve the MIME type
        const fileType = file.type;

        console.log(file);
        setImageBuffer(base64String);
        setFileType(fileType);
        // setIsPreviewMode(true);
      } else {
        // Handle the case where reader.result is undefined
        console.error("Error: FileReader result is undefined");
      }
    };
    return false;
  };

  return (
    <Modal title="Camera" open={visible} onCancel={onClose} footer={modalFooter} destroyOnClose maskClosable={false}>
      <Menu selectedKeys={[activeOption]} mode="horizontal">
        <Menu.Item key="upload" onClick={() => handleOptionChange("upload")} icon={<UploadOutlined />}>
          Upload
        </Menu.Item>
        <Menu.Item key="webcam" onClick={() => handleOptionChange("webcam")} icon={<CameraOutlined />}>
          Webcam
        </Menu.Item>
      </Menu>
      {activeOption === "upload" && (
        <Upload.Dragger
          name="file"
          customRequest={() => {}}
          onChange={(info) => {
            const { status } = info.file;
            if (status === "done") {
              message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === "error") {
              message.error(`${info.file.name} file upload failed.`);
            }
          }}
          beforeUpload={handleUpload}
          style={{ marginTop: "20px" }}
        >
          <p className="ant-upload-drag-icon">
            <UploadOutlined />
          </p>
          <p className="ant-upload-text">Click or drag file to this area to upload</p>
        </Upload.Dragger>
      )}
      {activeOption === "webcam" && (
        <>
          <Select
            value={selectedWebcam}
            style={{ marginBottom: 16, width: "100%" }}
            onChange={handleWebcamChange}
            disabled={webcams.length === 0}
          >
            {webcams.map((webcam, index) => (
              <Option key={index} value={index}>
                {webcam.label || `Webcam ${index + 1}`}
              </Option>
            ))}
          </Select>
          {isPreviewMode ? (
            // Show preview and disable webcam when in preview mode
            <Image src={`data:image/png;base64,${imageBuffer}`} alt="Preview" style={{ width: "100%" }} />
          ) : (
            // Show webcam when not in preview mode
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/png"
              videoConstraints={{ deviceId: selectedWebcam !== null ? webcams[selectedWebcam]?.deviceId : undefined }}
              width={"100%"}
              height={"100%"}
            />
          )}
          <WebcamButtons />
        </>
      )}
    </Modal>
  );
};

export default AddOrEditMemberImageModal;
