import { Layout, theme } from "antd";
import React from "react";
import PrivateHeader from "../../components/layout/PrivateHeader";
import { Outlet } from "react-router-dom";
import PrivateSider from "../../components/layout/PrivateSider";

const { Content } = Layout;

const PrivateLayout: React.FC = (data) => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  return (
    <Layout style={{ height: "100vh", overflowY: "hidden" }}>
      <PrivateSider />
      <Layout>
        <PrivateHeader />
        <Layout style={{ padding: "24px", height: "100vh" }}>
          <Content
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280,
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
              overflowY: "auto",
            }}
          >
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default PrivateLayout;
