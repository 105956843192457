export const setUserToken = (token: string) => {
    try {
        localStorage.setItem("token", token);
    } catch (error) {
        throw error
    }
}

export const logOutUser = () => {
    try {
        // Save user information and token to local storage or cookie
        localStorage.clear();
    } catch (error) {
        throw error
    }
}

export const getUserToken = (): string | null => {
    try {
        const token = localStorage.getItem("token");
        return token;
    } catch (error) {
        console.error("Error getting user token:", error);
        return null;
    }
};
