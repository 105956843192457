import React from "react";
import MemberDetails from "../../../../components/members/MembersViewDetails";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../../stores/rootReducers";
import { useGetMemberDetailsQuery } from "../../../../stores/apiSlices/membersApiSlice";
import LoaderScreen from "../../../../components/common/LoaderScreenComponent";
import ErrorComponent from "../../../../components/common/ErrorComponent";

const MemberViewDetailsContainer: React.FC = () => {
  const { selectedBranch } = useSelector((state: RootState) => state?.user);
  const params = useParams();
  const { memberCode } = params;

  const { data, isError, error, isLoading, isFetching } = useGetMemberDetailsQuery({
    clubBranchId: selectedBranch.clubBranchId,
    memberCode,
  });

  if (isError) {
    return <ErrorComponent error={error} />;
  }

  if (isLoading || isFetching) return <LoaderScreen />;

  return <MemberDetails memberData={data} />;
};

export default MemberViewDetailsContainer;
