import React from "react";
import PageHeader from "../../../components/common/PageHeader";
import AddEditMemberForm from "../../../components/members/AddEditMemberForm";
import { MemberAttributes, useCreateMemberMutation } from "../../../stores/apiSlices/membersApiSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../../stores/rootReducers";
import { useNavigate } from "react-router-dom";
import { MEMBERS_LIST_ALL } from "../../../routes";

const MemberAddContainer: React.FC = () => {
  const navigate = useNavigate();
  const { selectedBranch } = useSelector((state: RootState) => state?.user);
  const [createMember, { isLoading: addLoading }] = useCreateMemberMutation();

  const onSubmit = async (values: Partial<MemberAttributes>) => {
    // @ts-ignore
    values.clubBranchId = selectedBranch.clubBranchId;
    const response = await createMember(values);
    // @ts-ignore
    if (response?.data?.success) navigate(MEMBERS_LIST_ALL);
  };

  return (
    <>
      <PageHeader title="Add Member" />
      <AddEditMemberForm onSubmit={onSubmit} loading={addLoading} />
    </>
  );
};

export default MemberAddContainer;
