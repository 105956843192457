import React from "react";
import { Col, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import LoginForm from "../../components/login/LoginForm";
import { loginUser } from "../../stores/slices/userSlice";
import { RootState } from "../../stores/rootReducers";

const LoginContainer: React.FC = () => {
  const { loading } = useSelector((state: RootState) => state?.user);
  const dispatch = useDispatch();

  const onSubmit = async (values: object) => {
    try {
      // @ts-ignore
      dispatch(loginUser(values));
    } catch (error) {
      console.error("Login failed", error);
    }
  };
  return (
    <div
      style={{
        height: "100vh",
        backgroundImage: 'url("/background.jpg")', // Background image from the public folder
        backgroundSize: "cover", // Ensures the image covers the entire card
        backgroundPosition: "center", // Centers the image
        backgroundRepeat: "no-repeat", // Ensures the image doesn't repeat
      }}
    >
      <Row justify="center" align="middle" style={{ height: "100vh" }}>
        <Col xs={24} sm={18} md={16} lg={10} xl={7}>
          <LoginForm onSubmit={onSubmit} loading={loading} />
        </Col>
      </Row>
    </div>
  );
};

export default LoginContainer;
