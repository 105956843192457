import React from "react";
import { Modal, Spin } from "antd";

interface BackDropProps {
  visible: boolean;
}

const BackdropSpinner: React.FC<BackDropProps> = ({ visible }) => {
  return (
    <Modal
      open={visible}
      footer={null}
      closable={false}
      centered
      //   width={"100%"}
      maskClosable={false}
      style={{ backgroundColor: "transparent" }}
      bodyStyle={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Spin size="large" />
    </Modal>
  );
};

export default BackdropSpinner;
