import React, { useEffect, useState } from "react";
import { useGetMembersQuery } from "../../../stores/apiSlices/membersApiSlice";
import ErrorComponent from "../../../components/common/ErrorComponent";
import MembersListComponent from "../../../components/members/MembersListTable";
import PageHeader from "../../../components/common/PageHeader";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../stores/rootReducers";
import KpiComponent, { KpiComponentObject } from "../../../components/common/KpiComponent";
import { calculateDaysRemaining } from "../../../utils/luxon";

const MembersListContainer: React.FC = () => {
  const [kpiData, setKpiData] = useState<KpiComponentObject[]>([{ title: "Test", value: "9" }]);
  const { selectedBranch } = useSelector((state: RootState) => state?.user);
  const navigate = useNavigate();
  const { data, isError, error, isLoading, isFetching } = useGetMembersQuery(selectedBranch.clubBranchId);

  useEffect(() => {
    const calculateKpiData = () => {
      const tempKpiData = [];

      // Set Total Members
      tempKpiData.push({ title: "Total Members", value: data && data.length ? data.length : 0 });

      // Set Active Subscription
      const activeSubscription = data?.filter((ele) => ele.membershipStatus);
      tempKpiData.push({
        title: "Active Subscription",
        value: activeSubscription && activeSubscription.length ? activeSubscription.length : 0,
      });

      // Set Inactive Subscription
      const inactiveSubscription = data?.filter((ele) => !ele.membershipStatus);
      tempKpiData.push({
        title: "Inactive Subscription",
        value: inactiveSubscription && inactiveSubscription.length ? inactiveSubscription.length : 0,
      });

      // Set Membership Not assigned
      const membershipNotAssigned = data?.filter((ele) => !ele.packageName);
      tempKpiData.push({
        title: "Ghost Members",
        value: membershipNotAssigned && membershipNotAssigned.length ? membershipNotAssigned.length : 0,
      });

      // Set Membership Within
      let today = 0;
      let last7Days = 0;
      let last30Days = 0;
      let last365Days = 0;

      if (data && data.length) {
        const currentDate: any = new Date(); // Get current date

        for (const ele of data) {
          const createdAtDate: any = new Date(ele.createdAt);
          const daysWithin = Math.trunc((currentDate - createdAtDate) / (1000 * 60 * 60 * 24)); // Calculate difference in days

          if (daysWithin === 0) {
            today++;
          } else if (daysWithin > 0 && daysWithin <= 7) {
            last7Days++;
          } else if (daysWithin > 7 && daysWithin <= 30) {
            last30Days++;
          } else if (daysWithin > 30 && daysWithin <= 365) {
            last365Days++;
          }
        }
      }

      tempKpiData.push({
        title: "Joined Today",
        value: today,
      });
      tempKpiData.push({
        title: "Joined Past 7 Days",
        value: last7Days,
      });
      tempKpiData.push({
        title: "Joined Past 30 Days",
        value: last30Days,
      });
      tempKpiData.push({
        title: "Joined in a Year",
        value: last365Days,
      });

      // Set Membership Expiry
      let todayExpiry = 0;
      let last7DaysExpiry = 0;
      let last30DaysExpiry = 0;
      let last365DaysExpiry = 0;

      if (data && data.length) {
        for (const ele of data) {
          if (ele.membershipEndDate) {
            const daysWithin = Number(calculateDaysRemaining(ele.membershipEndDate));

            if (daysWithin === 0) {
              todayExpiry++;
            } else if (daysWithin > 1 && daysWithin <= 7) {
              last7DaysExpiry++;
            } else if (daysWithin > 7 && daysWithin <= 30) {
              last30DaysExpiry++;
            } else if (daysWithin > 30 && daysWithin <= 365) {
              last365DaysExpiry++;
            }
          }
        }
      }

      tempKpiData.push({
        title: "Expiring Today",
        value: todayExpiry,
      });
      tempKpiData.push({
        title: "Expiring in 7 Days",
        value: last7DaysExpiry,
      });
      tempKpiData.push({
        title: "Expiring in 30 Days",
        value: last30DaysExpiry,
      });
      tempKpiData.push({
        title: "Expiring in a Year",
        value: last365DaysExpiry,
      });

      // Set KPI Data
      setKpiData(tempKpiData);
    };
    calculateKpiData();
  }, [data]);

  const onButtonClick = () => {
    navigate("/members/list/add");
  };

  if (isError) {
    return <ErrorComponent error={error} />;
  }

  return (
    <>
      <div style={{ marginBottom: 8 }}>
        <PageHeader title="Members" displayButton={true} buttonText="Add" onButtonClick={onButtonClick} />
      </div>
      <div style={{ marginBottom: 8 }}>
        <KpiComponent data={kpiData} />
      </div>
      <MembersListComponent data={data} loading={isLoading || isFetching} />
    </>
  );
};

export default MembersListContainer;
