import { combineReducers } from "redux";
import { userReducers } from "./slices/userSlice";
import { membersApiReducer, membersReducerPath } from "./apiSlices/membersApiSlice";
import { packagesApiReducer, packagesReducerPath } from "./apiSlices/packagesApiSlice";
import { alertsApiReducer, alertsReducerPath } from "./apiSlices/alertsApiSlice";

const rootReducers = combineReducers({
    user: userReducers,
    [membersReducerPath]: membersApiReducer,
    [packagesReducerPath]: packagesApiReducer,
    [alertsReducerPath]: alertsApiReducer
});

export type RootState = ReturnType<typeof rootReducers>;

export default rootReducers;