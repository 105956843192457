import React, { useEffect, useState } from "react";
import { Card, Col, Row, Statistic, Space, Divider } from "antd";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { useSelector } from "react-redux";
import { RootState } from "../../stores/rootReducers";
import { useGetMembersQuery } from "../../stores/apiSlices/membersApiSlice";
import ErrorComponent from "../../components/common/ErrorComponent";
import LoaderScreen from "../../components/common/LoaderScreenComponent";
import MemberPieChart from "../../components/dashboard/MemberPieChart";
import { isDateWithinLast7Days } from "../../utils/luxon";

const data = [
  { name: "Jan", newMembers: 10, totalMembers: 50, activeMembers: 40 },
  { name: "Feb", newMembers: 15, totalMembers: 65, activeMembers: 55 },
  { name: "Mar", newMembers: 35, totalMembers: 115, activeMembers: 55 },
  // Add more months as needed
];

const DashboardContainer: React.FC = () => {
  const { selectedBranch } = useSelector((state: RootState) => state?.user);
  const { data: memberData, isError, error, isLoading, isFetching } = useGetMembersQuery(selectedBranch.clubBranchId);

  const [kpiCounts, setKpiCounts] = useState({
    totalMembers: 0,
    joinedLastWeek: 0,
    activeSubscription: 0,
    inactiveSubscription: 0,
  });

  useEffect(() => {
    if (memberData && memberData.length) {
      let totalMembers = 0;
      let joinedLastWeek = 0;
      let activeSubscription = 0;
      let inactiveSubscription = 0;

      for (const ele of memberData) {
        const memberAddedLastWeek = isDateWithinLast7Days(ele.createdAt);
        if (memberAddedLastWeek) joinedLastWeek += 1;
        if (ele.membershipStatus) {
          activeSubscription += 1;
        } else {
          inactiveSubscription += 1;
        }
        totalMembers += 1;
      }

      setKpiCounts({ totalMembers, joinedLastWeek, activeSubscription, inactiveSubscription });
    }
  }, [memberData]);

  if (isError) return <ErrorComponent error={error} />;
  if (isLoading || isFetching) return <LoaderScreen />;

  return (
    <Space style={{ width: "100%" }} direction="vertical" size={16}>
      <Row gutter={[16, 16]} justify="space-around">
        <Col xs={24} sm={12} lg={6} xl={6}>
          <Card>
            <Statistic title="Total Members" value={kpiCounts.totalMembers} />
          </Card>
        </Col>
        <Col xs={24} sm={12} lg={6} xl={6}>
          <Card>
            <Statistic title="Joined Last Week" value={kpiCounts.joinedLastWeek} />
          </Card>
        </Col>
        <Col xs={24} sm={12} lg={6} xl={6}>
          <Card>
            <Statistic title="Active Subscription" value={kpiCounts.activeSubscription} />
          </Card>
        </Col>
        <Col xs={24} sm={12} lg={6} xl={6}>
          <Card>
            <Statistic title="Inactive Subscription" value={kpiCounts.inactiveSubscription} />
          </Card>
        </Col>
      </Row>
      <Divider />
      <Row gutter={16} justify="space-around">
        <Col xs={24} sm={24} lg={6} xl={6}>
          <Card title="Active v/s Inactive Members">
            <MemberPieChart data={kpiCounts} />
          </Card>
        </Col>
        <Col xs={24} sm={24} lg={18} xl={18}>
          <Card title="Monthly Statistics">
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="newMembers" fill="#8884d8" />
                <Bar dataKey="totalMembers" fill="#82ca9d" />
                <Bar dataKey="activeMembers" fill="#ffc658" />
              </BarChart>
            </ResponsiveContainer>
          </Card>
        </Col>
      </Row>
    </Space>
  );
};

export default DashboardContainer;
