import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../../stores/rootReducers";
import {
  useAddMemberDocumentsMutation,
  useGetMemberDocumentsQuery,
} from "../../../../stores/apiSlices/membersApiSlice";
import LoaderScreen from "../../../../components/common/LoaderScreenComponent";
import ErrorComponent from "../../../../components/common/ErrorComponent";
import ListMemberDocuments from "../../../../components/members/ListMemberDocuments";
import AddMemberDocumentModal from "../../../../components/members/AddMemberDocumentModal";
import PageHeader from "../../../../components/common/PageHeader";

const MemberDocumentsContainer: React.FC = () => {
  const [open, setOpen] = useState(false);
  const { selectedBranch } = useSelector((state: RootState) => state?.user);
  const params = useParams();
  const { memberCode } = params;

  const { data, isError, error, isLoading, isFetching } = useGetMemberDocumentsQuery({
    clubBranchId: selectedBranch.clubBranchId,
    memberCode,
  });

  const [addDocument, { isLoading: memberDocumentAddLoading }] = useAddMemberDocumentsMutation();

  const openDrawer = () => {
    setOpen(true);
  };

  const closeAddDrawer = () => {
    setOpen(false);
  };

  const onFormSubmit = async (values: any) => {
    values.clubBranchId = selectedBranch.clubBranchId;
    values.memberCode = memberCode;
    const response = await addDocument(values);

    //@ts-ignore
    if (response?.data?.success) closeAddDrawer();
  };

  if (isError) {
    return <ErrorComponent error={error} />;
  }

  if (isLoading || isFetching) return <LoaderScreen />;

  return (
    <div>
      <AddMemberDocumentModal
        open={open}
        closeAddDrawer={closeAddDrawer}
        onFormSubmit={onFormSubmit}
        addDocumentLoading={memberDocumentAddLoading}
      />
      <PageHeader title="View Documents" displayButton={true} buttonText="Add" onButtonClick={openDrawer} />
      <ListMemberDocuments data={data} />
    </div>
  );
};

export default MemberDocumentsContainer;
