import React, { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../../stores/rootReducers";
import { useApplyMembershipMutation, useGetMembershipDetailsQuery } from "../../../../stores/apiSlices/membersApiSlice";
import LoaderScreen from "../../../../components/common/LoaderScreenComponent";
import ErrorComponent from "../../../../components/common/ErrorComponent";
import MembershipCard from "../../../../components/members/ViewMembershipCard";
import NoDataAvailable from "../../../../components/common/NoContent";
import PageHeader from "../../../../components/common/PageHeader";
import AddMembershipModal from "../../../../components/members/AddMembershipModal";

const MembershipContainer: React.FC = () => {
  const [open, setOpen] = useState(false);

  const { selectedBranch } = useSelector((state: RootState) => state?.user);
  const params = useParams();
  const location = useLocation();

  const pathNameArr = location.pathname.split("/");
  const { memberCode } = params;

  const { data, isError, error, isLoading, isFetching } = useGetMembershipDetailsQuery({
    clubBranchId: selectedBranch.clubBranchId,
    memberCode,
  });

  const [applyMembership, { isLoading: applyMembershipLoading }] = useApplyMembershipMutation();

  if (isError) {
    return <ErrorComponent error={error} />;
  }

  if (isLoading || isFetching) return <LoaderScreen />;

  const onAddClick = () => {
    setOpen(true);
  };

  const closeAddDrawer = () => {
    setOpen(false);
  };

  const onFormSubmit = async (values: any) => {
    values.clubBranchId = selectedBranch.clubBranchId;
    values.memberCode = pathNameArr[4];
    const response = await applyMembership(values);

    //@ts-ignore
    if (response?.data?.success) closeAddDrawer();
  };
  return (
    <div>
      <AddMembershipModal
        open={open}
        closeAddDrawer={closeAddDrawer}
        onFormSubmit={onFormSubmit}
        applyMembershipLoading={applyMembershipLoading}
      />
      <PageHeader title="All Membership" displayButton={true} buttonText="Add New" onButtonClick={onAddClick} />
      {data && data[0] ? (
        data.map((ele: object, idx) => {
          return <MembershipCard key={"membership_user_" + idx} data={ele} />;
        })
      ) : (
        <NoDataAvailable />
      )}
    </div>
  );
};

export default MembershipContainer;
