// src/Login.tsx
import React, { useEffect, useState } from "react";
import { RouterProvider } from "react-router-dom";
import publicRoutes from "./routes/publicRoutes";
import { useSelector } from "react-redux";
import { RootState } from "./stores/rootReducers";
import privateRoutes from "./routes/privateRoutes";
import { ConfigProvider, theme } from "antd";
import enUS from "antd/locale/en_US";

const usePreferredColorScheme = () => {
  const [themeMode, setThemeMode] = useState('light');

  useEffect(() => {
    const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const setPreferredColorScheme = () => {
      setThemeMode(darkModeMediaQuery.matches ? 'dark' : 'light');
    };

    setPreferredColorScheme();
    darkModeMediaQuery.addEventListener('change', setPreferredColorScheme);

    return () => darkModeMediaQuery.removeEventListener('change', setPreferredColorScheme);
  }, []);

  return themeMode;
};

const Wrapper: React.FC = () => {
  const { isAuthenticated } = useSelector((state: RootState) => state?.user);
  return isAuthenticated ? <RouterProvider router={privateRoutes} /> : <RouterProvider router={publicRoutes} />;
};

const App: React.FC = () => {
  const themeMode = usePreferredColorScheme();
  const { systemDarkMode, darkMode, compactTheme, themeColor } = useSelector((state: RootState) => state?.user);

  const algorithmArray = [systemDarkMode ? themeMode === 'dark' ? theme.darkAlgorithm : theme.defaultAlgorithm : darkMode ? theme.darkAlgorithm : theme.defaultAlgorithm]

  if (compactTheme) algorithmArray.push(theme.compactAlgorithm)

  return (<ConfigProvider
    theme={{
      // 1. Use dark algorithm
      token: {
        colorPrimary: themeColor,
      },
      components: {
        Statistic: {
          titleFontSize: 15
        },
      },
      algorithm: algorithmArray,

      // 2. Combine dark algorithm and compact algorithm
      // algorithm: [theme.darkAlgorithm, theme.compactAlgorithm],
    }}
    locale={enUS}
  >
    <Wrapper />
  </ConfigProvider>)

}

export default App;
