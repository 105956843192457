import React from "react";
import { Result, Typography } from "antd";

interface ErrorProps {
  error: any;
}

const ErrorComponent: React.FC<ErrorProps> = ({ error }) => {
  return (
    <Result
      status="error"
      title="Error"
      subTitle={<Typography.Text strong>{error.data.message}</Typography.Text>}
      //   extra={<Button type="primary">Go Back</Button>}
    />
  );
};

export default ErrorComponent;
