import React, { useEffect, useState } from "react";
import { ProTable } from "@ant-design/pro-table";
import { MemberAttributes } from "../../stores/apiSlices/membersApiSlice";
import { Button, Image, Input, Space, Typography } from "antd";
import { EditOutlined, EyeOutlined, WhatsAppOutlined, SearchOutlined } from "@ant-design/icons";
import { calculateAge, handleOpenWhatsApp } from "../../utils/helper";
import GenderComponent from "./GenderComponent";
import { useNavigate } from "react-router-dom";
import { MEMBER_EDIT, MEMBER_VIEW_DETAILS } from "../../routes";
import { extractDateNTime } from "../../utils/luxon";
import MembershipStatus from "./common/MembershipStatus";
import { MEMBER_IMAGES_BASE_PATH } from "../../utils/consants";

const MembersListComponent: React.FC<{ data: MemberAttributes[] | undefined; loading: boolean }> = ({
  data,
  loading,
}) => {
  const [filteredData, setFilteredData] = useState(data || []);
  useEffect(() => {
    if (data && data.length) {
      setFilteredData(data);
    }
  }, [data]);
  const navigate = useNavigate();
  const handleEdit = (memberCode: string) => {
    navigate(MEMBER_EDIT + memberCode);
  };
  const handleViewProfile = (memberCode: string) => {
    navigate(MEMBER_VIEW_DETAILS + memberCode);
  };
  const columns = [
    {
      title: "Sr No",
      valueType: "indexBorder",
    },
    {
      title: "Member ID",
      dataIndex: "memberCode",
      key: "memberCode",
      sorter: (a: any, b: any) => a.memberCode.localeCompare(b.memberCode),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text: any, record: Partial<MemberAttributes>) => (
        <Space align="center" size={8}>
          <Image
            alt={text}
            fallback="/assets/images/avatar.png"
            src={MEMBER_IMAGES_BASE_PATH + record.image}
            width={"80px"}
            height={"80px"}
            style={{ borderRadius: "50%" }}
          />
          <Typography.Text>{text}</Typography.Text>
        </Space>
      ),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
        <div style={{ padding: 8 }}>
          <Input.Search
            placeholder="Search name"
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm()}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => confirm()}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
                confirm(); // This line ensures that after clearing filters, the table is updated
              }}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
      onFilter: (value: any, record: any) => record.name.toLowerCase().includes(value.toLowerCase()),
      sorter: (a: any, b: any) => a.name.localeCompare(b.name), // Add a sorter for sorting by 'Name'
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      render: (gender: any) => (
        <Space>
          <GenderComponent gender={gender} />
        </Space>
      ),
      filters: [
        { text: "Male", value: "M" },
        { text: "Female", value: "F" },
      ],
      onFilter: (value: any, record: any) => record.gender === value,
    },
    {
      title: "Age",
      dataIndex: "dob",
      key: "dob",
      render: (text: any) => calculateAge(text),
      sorter: (a: any, b: any) => a.dob.localeCompare(b.dob),
    },
    // {
    //   title: "Image",
    //   dataIndex: "image",
    //   key: "image",
    //   render: (text: any) => <img src={text} alt="Profile" style={{ width: 50, height: 50 }} />,
    // },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
      // render: (_text: any, record: any) => record.countryCode + "-" + record.phoneNumber,
      render: (_text: any, record: any) => (
        // <Space>{text === "Active" ? <Tag color="green">Active</Tag> : <Tag color="red">Inactive</Tag>}</Space>

        <Space direction="vertical" align="center" size={8}>
          <Typography.Text>
            {record.countryCode}-{record.phoneNumber}
          </Typography.Text>
          <Button type="primary" icon={<WhatsAppOutlined />} onClick={() => handleOpenWhatsApp(record.mobile)}>
            WhatsApp
          </Button>
        </Space>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Membership Status",
      dataIndex: "membershipStatus",
      key: "membershipStatus",
      render: (_text: any, data: any) => (
        // <Space>{text === "Active" ? <Tag color="green">Active</Tag> : <Tag color="red">Inactive</Tag>}</Space>

        <Space align="center" size={8}>
          <MembershipStatus isActive={data.membershipStatus} endDate={data.membershipEndDate} />
        </Space>
      ),
      filters: [
        { text: "Active", value: "Active" },
        { text: "Inactive", value: "Inactive" },
      ],
      onFilter: (value: any, record: any) => {
        const selectedValue = record.membershipStatus ? "Active" : "Inactive";
        return selectedValue === value;
      },
      sorter: (a: any, b: any) => {
        if (a.membershipEndDate && b.membershipEndDate) {
          return a.membershipEndDate.localeCompare(b.membershipEndDate);
        }
        // Handle null values - you can adjust this part based on your sorting requirements
        return 0;
      },
    },
    {
      title: "Last Package",
      dataIndex: "packageName",
      key: "packageName",
    },
    {
      title: "Created On",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text: any) => extractDateNTime(text),
    },
    {
      title: "Action",
      key: "Action",
      render: (_text: any, record: any) => (
        <Space>
          <Button
            title="Edit"
            type="link"
            icon={<EditOutlined />}
            size="small"
            onClick={() => handleEdit(record.memberCode)}
          />
          <Button
            title="View"
            type="link"
            icon={<EyeOutlined />}
            size="small"
            onClick={() => handleViewProfile(record.memberCode)}
          />
        </Space>
      ),
    },
  ];

  return (
    <ProTable
      dataSource={filteredData || []}
      columns={columns}
      loading={loading}
      search={false}
      toolbar={{
        search: {
          placeholder: "Search Name Number or Email...",
          onSearch: (value: string) => {
            // Your search logic here
            const searchResults =
              data && data[0]
                ? data.filter(
                    (member) =>
                      member.firstName?.toLowerCase().includes(value.toLowerCase()) ||
                      member.lastName?.toLowerCase().includes(value.toLowerCase()) ||
                      member.mobile?.includes(value) ||
                      member.email?.toLowerCase().includes(value.toLowerCase())
                  )
                : [];
            setFilteredData(searchResults);
          },
          onReset: () => {
            console.log("reset clicked");
            setFilteredData(data || []);
          },
        },
      }}
    />
  );
};

export default MembersListComponent;
