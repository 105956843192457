import { Navigate, createBrowserRouter } from "react-router-dom";
import LoginPage from "../pages/login";

const publicRoutes = createBrowserRouter([
  {
    path: "*",
    element: <Navigate to="/login" replace />,
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
]);

export default publicRoutes;
