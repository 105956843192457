import React from "react";
import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import { Space, Button, Switch } from "antd";
import { PackageAttributes } from "../../stores/apiSlices/packagesApiSlice";
import ProTable from "@ant-design/pro-table";
import { extractDateNTime } from "../../utils/luxon";

interface ListPackagesProps {
  data: PackageAttributes[];
  loading: boolean;
  onViewClick: (packageId: number) => void;
  onEditClick: (packageId: number) => void;
  onToggleActive: (packageId: number, isActive: boolean) => void;
}

const ListPackages: React.FC<ListPackagesProps> = ({ data, loading, onViewClick, onEditClick, onToggleActive }) => {
  const columns = [
    {
      title: "Sr No",
      valueType: "indexBorder",
    },
    {
      title: "ID",
      dataIndex: "packageId",
      key: "packageId",
    },
    {
      title: "Package Name",
      dataIndex: "packageName",
      key: "packageName",
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (text: any) => <p>&#8377;{text}</p>,
    },
    {
      title: "Final Price",
      dataIndex: "finalPrice",
      key: "finalPrice",
      render: (text: any) => <p>&#8377;{text}</p>,
    },
    {
      title: "GST",
      dataIndex: "igst",
      key: "igst",
      render: (text: any) => text + " %",
    },
    {
      title: "Created On",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text: any) => extractDateNTime(text),
    },
    {
      title: "Updated On",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (text: any) => extractDateNTime(text),
    },
    {
      title: "Status",
      key: "status",
      render: (_text: any, record: PackageAttributes) => (
        <Space size="middle">
          <Switch checked={record.isActive} onChange={(checked) => onToggleActive(record.packageId, checked)} />
        </Space>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_text: any, record: PackageAttributes) => (
        <Space size="middle">
          <Button
            title="Edit"
            type="link"
            icon={<EditOutlined />}
            size="small"
            onClick={() => onEditClick(record.packageId)}
          />
          <Button
            title="View"
            type="link"
            icon={<EyeOutlined />}
            size="small"
            onClick={() => onViewClick(record.packageId)}
          />
        </Space>
      ),
    },
  ];

  return <ProTable loading={loading} search={false} dataSource={data} columns={columns} />;
};

export default ListPackages;
