import { Navigate, createBrowserRouter } from "react-router-dom";
import DashboardPage from "../pages/Dashboard";
import PrivateLayout from "../containers/privateLayout";
import MembersListPage from "../pages/members/list";
import MemberAddPage from "../pages/members/add";
import MemberViewDetailsPage from "../pages/members/list/viewDetails";
import MemberHeaderLayout from "../components/layout/MemberHeader";
import PackagePage from "../pages/config/packages";
import PackageAddPage from "../pages/config/add";
import PackageUpdatePage from "../pages/config/packageUpdate";
import MembershipPage from "../pages/members/list/membership";
import MemberActivityPage from "../pages/members/list/activity";
import MemberDocumentsPage from "../pages/members/list/documents";
import MemberEditPage from "../pages/members/edit";
import WhatsappConfigPage from "../pages/whatsapp";
import LogoutUserPage from "../pages/logout/LogoutUserPage";
import SmsPage from "../pages/alerts/SmsPage";
import SalesReportPage from "../pages/reports/SalesReportPage";

const privateRoutes = createBrowserRouter([
  {
    path: "/logout",
    element: <LogoutUserPage />,
  },
  {
    path: "*",
    element: <Navigate to="/dashboard" replace />,
  },
  {
    element: <PrivateLayout />, // Wrap all routes with the layout
    children: [
      {
        path: "/dashboard",
        element: <DashboardPage />,
      },
      {
        path: "/members/list",
        element: <MembersListPage />,
      },
      {
        path: "/members/add",
        element: <MemberAddPage />,
      },
      {
        path: "/members/list/add",
        element: <MemberAddPage />,
      },
      {
        path: "/members/list/edit/:memberCode",
        element: <MemberEditPage />,
      },
      {
        element: <MemberHeaderLayout />,
        children: [
          {
            path: "/members/list/view-details/:memberCode",
            element: <MemberViewDetailsPage />,
          },
          {
            path: "/members/list/view-membership/:memberCode",
            element: <MembershipPage />,
          },
          {
            path: "/members/list/view-activity/:memberCode",
            element: <MemberActivityPage />,
          },
          {
            path: "/members/list/view-documents/:memberCode",
            element: <MemberDocumentsPage />,
          },
        ],
      },
      {
        path: "/alerts/sms",
        element: <SmsPage />,
      },
      {
        path: "/reports/sales",
        element: <SalesReportPage />,
      },
      {
        path: "/config/packages",
        element: <PackagePage />,
      },
      {
        path: "/config/packages/add",
        element: <PackageAddPage />,
      },
      {
        path: "/config/packages/edit/:packageId",
        element: <PackageUpdatePage />,
      },
      {
        path: "/config/whatsapp",
        element: <WhatsappConfigPage />,
      },
    ],
  },
]);

export default privateRoutes;
