import React from "react";
import { ProTable } from "@ant-design/pro-table";
import { extractDateNTime } from "../../utils/luxon";
import { Button } from "antd";
import { MEMBER_DOCUMENTS_BASE_PATH } from "../../utils/consants";

interface MemberDocumentsProps {
  data: any;
}

interface FileViewerProps {
  filepath: string;
}

export const FileViewer: React.FC<FileViewerProps> = ({ filepath }) => {
  const handleViewFile = () => {
    // Open the file view route in a new tab
    window.open(filepath, "_blank");
  };

  return (
    <div>
      <Button type="primary" onClick={handleViewFile}>
        View Document
      </Button>
    </div>
  );
};

const ListMemberDocuments: React.FC<MemberDocumentsProps> = ({ data }) => {
  const columns = [
    {
      title: "Sr No",
      valueType: "indexBorder",
    },
    {
      title: "Type",
      dataIndex: "type",
    },
    {
      title: "Document Number",
      dataIndex: "documentNumber",
    },
    {
      title: "File",
      dataIndex: "file",
      render: (text: any, _record: any) =>
        text !== "-" ? <FileViewer filepath={MEMBER_DOCUMENTS_BASE_PATH + text} /> : text,
    },
    {
      title: "Description",
      dataIndex: "description",
    },
    {
      title: "Created On",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text: any) => extractDateNTime(text),
    },
    {
      title: "Updated On",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (text: any) => extractDateNTime(text),
    },
  ];

  return (
    <ProTable
      columns={columns}
      dataSource={data}
      rowKey="documentId"
      pagination={{ pageSize: 10 }}
      search={false}
      dateFormatter="string"
      headerTitle="Document Table"
    />
  );
};

export default ListMemberDocuments;
