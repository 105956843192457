import React from "react";
import ProForm, { ProFormText, ProFormDigit } from "@ant-design/pro-form";
import { FormInstance } from "antd";
import { Col, Row } from "antd";

interface AddEditPackageFormProps {
  onSubmit: any;
  loading: boolean;
  initialValues: any;
}

const AddEditPackageForm: React.FC<AddEditPackageFormProps> = ({ onSubmit, loading, initialValues = {} }) => {
  const formRef = React.createRef<FormInstance>();

  const layout = {
    lg: 8,
    md: 12,
    sm: 24,
  };

  return (
    <ProForm
      formRef={formRef}
      onFinish={onSubmit}
      loading={loading}
      initialValues={{
        ...initialValues,
        // Set default values or leave empty based on your requirements
      }}
    >
      <Row gutter={16}>
        <Col {...layout}>
          <ProFormText
            name="packageName"
            label="Package Name"
            placeholder="Enter package name"
            rules={[{ required: true, message: "Please enter package name" }]}
          />
        </Col>
        <Col {...layout}>
          <ProFormText
            name="description"
            label="Description"
            placeholder="Enter package description"
            rules={[{ required: true, message: "Please enter package description" }]}
          />
        </Col>
        <Col {...layout}>
          <ProFormText
            name="duration"
            label="Duration"
            placeholder="Enter duration"
            rules={[{ required: true, message: "Please enter duration" }]}
          />
        </Col>
        <Col {...layout}>
          <ProFormDigit
            name="durationInDays"
            label="Duration in Days"
            placeholder="Enter duration in days"
            rules={[{ required: true, message: "Please enter duration in days" }]}
          />
        </Col>
        <Col {...layout}>
          <ProFormDigit
            name="price"
            label="Price"
            placeholder="Enter price"
            rules={[{ required: true, message: "Please enter price" }]}
          />
        </Col>
        <Col {...layout}>
          <ProFormDigit
            name="finalPrice"
            label="Final Price"
            placeholder="Enter final price"
            rules={[{ required: true, message: "Please enter final price" }]}
          />
        </Col>
        <Col {...layout}>
          <ProFormDigit
            name="cgst"
            label="CGST"
            placeholder="Enter CGST"
            rules={[{ required: true, message: "Please enter CGST" }]}
          />
        </Col>
        <Col {...layout}>
          <ProFormDigit
            name="sgst"
            label="SGST"
            placeholder="Enter SGST"
            rules={[{ required: true, message: "Please enter SGST" }]}
          />
        </Col>
        <Col {...layout}>
          <ProFormDigit
            name="igst"
            label="IGST"
            placeholder="Enter IGST"
            rules={[{ required: true, message: "Please enter IGST" }]}
          />
        </Col>
      </Row>
    </ProForm>
  );
};

export default AddEditPackageForm;
