import React, { useState } from "react";
import { Layout, Menu, Dropdown, Avatar, Space, Typography, Image, theme, Divider, Switch, Button, ColorPicker } from "antd";
import { DownOutlined, UserOutlined, LogoutOutlined, ProfileOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../stores/slices/userSlice";
import { RootState } from "../../stores/rootReducers";
import GlobalSearchComponent from "./GlobalSearch";
import { CLIENT_DOCUMENTS_BASE_PATH } from "../../utils/consants";
import { Color } from "antd/es/color-picker";

const { Header } = Layout;
const { SubMenu } = Menu;

const PrivateHeader: React.FC = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false)
  const { userData, clubData, selectedBranch, darkMode, systemDarkMode, compactTheme, themeColor } = useSelector((state: RootState) => state?.user);

  console.log('themeColor', themeColor)
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const logOut = () => {
    dispatch(userActions.resetLoginProfile());
  };

  const subMenuClick = (data: any) => {
    const { key } = data;
    dispatch(userActions.switchBranch({ key }));
  };

  const handleDarkModeChange = () => {
    dispatch(userActions.setDarkMode(darkMode))
  }

  const handleSystemDarkMode = () => {
    dispatch(userActions.setSystemDarkMode(systemDarkMode))
  }

  const handleCompactTheme = () => {
    dispatch(userActions.setCompactTheme(compactTheme))
  }

  const handleColorChange = (color: Color) => {
    const hexColor = color.toHexString();
    dispatch(userActions.setThemeColor(hexColor))
  };


  const profileMenu = (
    <Menu style={{ width: "270px" }}>
      <Menu.ItemGroup title="Profile">
        <Menu.Item key="viewProfile" icon={<ProfileOutlined />}>
          Your Profile
        </Menu.Item>
        <Menu.Item onClick={logOut} key="logout" icon={<LogoutOutlined />}>
          Log Out
        </Menu.Item>
      </Menu.ItemGroup>
      <Divider />
      <Menu.ItemGroup title="Dark Theme">
        <Menu.Item key="auto">
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
            <span>Auto</span>
            <Switch
              checked={systemDarkMode}
              onChange={handleSystemDarkMode}
              checkedChildren={"On"}
              unCheckedChildren={"Off"}
            />
          </div>
        </Menu.Item>
        {!systemDarkMode ?
          <Menu.Item key="custom" disabled={systemDarkMode}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
              <span>Dark Mode</span>
              <Switch
                title="Dark Mode"
                checked={darkMode}
                checkedChildren="On"
                unCheckedChildren="Off"
                onChange={handleDarkModeChange}
              />
            </div>
          </Menu.Item> : null
        }
      </Menu.ItemGroup>
      <Divider />
      <Menu.ItemGroup style={{ marginBottom: '10px' }} title="Theme">
        <Menu.Item key="compact_theme">
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
            <span>Compact</span>
            <Switch
              checked={compactTheme}
              onChange={handleCompactTheme}
              checkedChildren={"On"}
              unCheckedChildren={"Off"}
            />
          </div>
        </Menu.Item>
        <Menu.Item key="color_theme">
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
            <span>Choose Color</span>
            <ColorPicker
              defaultValue={themeColor}
              onChangeComplete={(color) => {
                handleColorChange(color);
              }} />
          </div>
        </Menu.Item>
      </Menu.ItemGroup>

    </Menu >
  );

  return (
    <Header
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0 20px",
        background: colorBgContainer,
      }}
    >
      <GlobalSearchComponent />

      <Space style={{ display: "flex", alignItems: "center" }}>
        <Image src={CLIENT_DOCUMENTS_BASE_PATH + clubData.logo} width={40} height={40} alt="club_one_logo" />
        <Typography.Title level={3} style={{ margin: 0 }}>
          {clubData.name}
        </Typography.Title>
        <Menu
          mode="horizontal"
          defaultSelectedKeys={[selectedBranch.clubBranchId + ""]}
          onClick={subMenuClick}
          disabledOverflow
        >
          <SubMenu
            key="submenu"
            title={selectedBranch.name}
            icon={<DownOutlined />}
            style={{ backgroundColor: "rgba(0, 0, 0, 0)", color: "inherit" }}
          >
            {clubData && clubData.branches
              ? clubData.branches.map((item) => <Menu.Item key={item.clubBranchId}>{item.name}</Menu.Item>)
              : null}
          </SubMenu>
        </Menu>

        <Dropdown
          overlay={profileMenu}
          open={open}>
          <Button onClick={() => setOpen(!open)} type="link" style={{ display: 'flex', alignItems: 'center', padding: 0 }}>
            <Avatar icon={<UserOutlined />} style={{ marginRight: 8 }} />
            <Typography.Text>{userData?.firstName + " " + userData?.lastName}</Typography.Text>
          </Button>
        </Dropdown>
      </Space>
    </Header>
  );
};

export default PrivateHeader;
