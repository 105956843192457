import React from "react";
import { ProTable, ProColumns } from "@ant-design/pro-table";
import { extractDate, extractTime } from "../../utils/luxon";

interface TableProps {
  data: any; // Replace 'any' with the type of your data
  loading: boolean;
}

const columns: ProColumns<any>[] = [
  {
    title: "Sr No",
    valueType: "indexBorder",
  },
  {
    title: "Check In Date",
    dataIndex: "checkIn",
    key: "checkIn",
    render: (text: any, _record: any) => extractDate(text),
  },
  {
    title: "Check In Time",
    dataIndex: "checkIn",
    key: "checkIn",
    render: (text: any, _record: any) => extractTime(text),
  },
  {
    title: "Check Out Date",
    dataIndex: "checkOut",
    key: "checkOut",
    render: (text: any, _record: any) => (text !== "-" ? extractDate(text) : "-"),
  },
  {
    title: "Check Out Time",
    dataIndex: "checkOut",
    key: "checkOut",
    render: (text: any, _record: any) => (text !== "-" ? extractTime(text) : "-"),
  },
];

const ListMemberActivity: React.FC<TableProps> = ({ data, loading }) => {
  return <ProTable columns={columns} dataSource={data || []} search={false} loading={loading} />;
};

export default ListMemberActivity;
