import React from "react";
import { Typography } from "antd";

// Define the props type for the component
interface BreathingTitleProps {
  content: string; // The text to display
}

const { Title } = Typography;

const BreathingTitle: React.FC<BreathingTitleProps> = ({ content }) => {
  return (
    <Title className="breathing" style={{ textAlign: "center", color: "#663090" }} level={4}>
      {content}
    </Title>
  );
};

export default BreathingTitle;
