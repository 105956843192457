import React, { useState } from "react";
import DateRangePickerForm from "../../../../components/common/DateRangePicker";
import { useSelector } from "react-redux";
import { RootState } from "../../../../stores/rootReducers";
import { useCheckInMemberMutation, useGetMemberActivityQuery } from "../../../../stores/apiSlices/membersApiSlice";
import ErrorComponent from "../../../../components/common/ErrorComponent";
import { useParams } from "react-router-dom";
import ListMemberActivity from "../../../../components/members/ListMemberActivity";

const MemberActivityContainer: React.FC = () => {
  const [date, setDate] = useState([new Date().toLocaleDateString(), new Date().toLocaleDateString()]);

  const { selectedBranch } = useSelector((state: RootState) => state?.user);
  const params = useParams();
  const { memberCode } = params;

  const { data, isError, error, isLoading, isFetching } = useGetMemberActivityQuery({
    clubBranchId: selectedBranch.clubBranchId,
    memberCode,
    startDate: date[0],
    endDate: date[1],
  });

  const [checkInMember, { isLoading: checkInLoading }] = useCheckInMemberMutation();

  const onCheckInClick = async () => {
    const clubBranchId = selectedBranch.clubBranchId;
    await checkInMember({ clubBranchId, memberCode });
  };

  if (isError) {
    return <ErrorComponent error={error} />;
  }

  const onSubmit = (values: any) => {
    setDate(values.dateRange);
  };

  return (
    <div>
      <DateRangePickerForm onSubmit={onSubmit} onCheckInClick={onCheckInClick} loading={checkInLoading} />
      <ListMemberActivity data={data} loading={isLoading || isFetching} />
    </div>
  );
};

export default MemberActivityContainer;
