import React, { useState } from "react";
import { Button, Space, Row, Col, Typography } from "antd";
import { DateTime } from "luxon";
import MyDatePicker from "../common/DatePicker";

const { Title } = Typography;

// Define the component's prop types
interface DateSelectorProps {
    date: DateTime;
    endDate: DateTime;
    setDate: (date: DateTime) => void;
    setEndDate: (date: DateTime) => void;
}

const SalesReportDatePicker: React.FC<DateSelectorProps> = ({ date, endDate, setDate, setEndDate }) => {
    const [selectedDates, setSelectedDates] = useState<[DateTime, DateTime] | null>([date, endDate]);

    const handleSubmit = () => {
        if (selectedDates) {
            setDate(selectedDates[0]);
            setEndDate(selectedDates[1]);
        }
    };

    return (
        <Row justify="end" align="middle" style={{ width: '100%', textAlign: 'right' }}>
            <Col>
                <Title level={4} style={{ margin: '0 16px 0 0' }}>Select Date Range:</Title>
            </Col>
            <Col>
                <Space size="large">
                    <MyDatePicker.RangePicker
                        value={selectedDates}
                        onChange={(dates: any) => {
                            setSelectedDates([dates[0], dates[1]]);
                        }}
                    />
                    <Button type="primary" onClick={handleSubmit}>
                        Submit
                    </Button>
                </Space>
            </Col>
        </Row>
    );
};

export default SalesReportDatePicker;
