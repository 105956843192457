import React from "react";
import { Card, Col, Row, Statistic, Space, StatisticProps } from "antd";
import CountUp from 'react-countup';

const formatter: StatisticProps['formatter'] = (value) => (
  <CountUp end={value as number} separator="," />
);


export interface KpiComponentObject {
  title: string;
  value: string | number;
  prefix?: string;
}

export interface KpiComponentProps {
  data: KpiComponentObject[];
}

const KpiComponent: React.FC<KpiComponentProps> = ({ data }) => {
  return (
    <Space style={{ width: "100%" }} direction="vertical" size={16}>
      <Row gutter={[16, 16]} justify="start">
        {data &&
          data.map((ele, index) => {
            return (
              <Col key={index} xs={12} sm={8} lg={4} xl={4}>
                <Card >
                  <Statistic title={ele.title} value={ele.value} prefix={ele.prefix} formatter={formatter} />
                </Card>
              </Col>
            );
          })}
      </Row>
    </Space >
  );
};

export default KpiComponent;
