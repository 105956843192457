import React, { useState } from "react";
import { Button, Drawer, Form, Space, Radio, Input, Divider } from "antd";
import { CameraOutlined } from "@ant-design/icons";
import { ENUM_DOCUMENT_TYPE } from "../../utils/enums";
import AddOrEditMemberImageModal from "./AddOrEditMemberImage";
import { useUploadMemberDocumentMutation } from "../../stores/apiSlices/membersApiSlice";
import { base64StringToBlob } from "../../utils/helper";
import { FileViewer } from "./ListMemberDocuments";
import { MEMBER_DOCUMENTS_BASE_PATH } from "../../utils/consants";

interface FormData {
  packageId: number;
  paymentType: string;
  startDate: string;
}

interface YourFormProps {
  onSubmit: (formData: FormData) => void;
  addDocumentLoading: boolean;
}

interface AddMemberDocumentsProps {
  open: boolean;
  addDocumentLoading: boolean;
  closeAddDrawer: any;
  onFormSubmit: any;
}

const AddMembershipForm: React.FC<YourFormProps> = ({ onSubmit, addDocumentLoading }) => {
  const onFinish = (values: FormData) => {
    onSubmit(values);
  };

  return (
    <Form onFinish={onFinish} layout="vertical">
      <Form.Item
        label="Document Type"
        name="type"
        rules={[{ required: true, message: "Please select a Document type" }]}
      >
        <Radio.Group>
          {Object.values(ENUM_DOCUMENT_TYPE).map((documentType) => (
            <Radio key={documentType} value={documentType}>
              {documentType}
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>

      <Form.Item
        label="Document Number"
        name="documentNumber"
        rules={[{ required: true, message: "Please enter the document number" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item label="Description" name="description">
        <Input.TextArea rows={3} />
      </Form.Item>

      <Form.Item>
        <Button loading={addDocumentLoading} type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

const AddMemberDocumentModal: React.FC<AddMemberDocumentsProps> = ({
  open,
  closeAddDrawer,
  onFormSubmit,
  addDocumentLoading,
}) => {
  const [file, setFile] = useState("");
  const [uploadModal, setUploadModal] = useState(false);
  const [uploadDocument, { isLoading: uploadDocumentLoading }] = useUploadMemberDocumentMutation();

  const openUploadModal = () => {
    setUploadModal(true);
  };

  const closeUploadModal = () => {
    setUploadModal(false);
  };

  const onSubmitUploadModal = async (file: any, fileType: any) => {
    const newData = new FormData();
    const imageBuffer = base64StringToBlob(file, fileType);

    newData.append("file", imageBuffer);
    const response = await uploadDocument({ newData });
    //@ts-ignore
    setFile(response.data?.data?.fileName || "");
    //@ts-ignore
    if (response?.data?.success) closeUploadModal();
  };
  console.log("file", file);

  const onSubmit = (values: any = {}) => {
    if (file) {
      values.file = file;
    }
    onFormSubmit(values);
  };
  return (
    <>
      <AddOrEditMemberImageModal
        visible={uploadModal}
        onClose={closeUploadModal}
        onSubmit={onSubmitUploadModal}
        modalLoading={uploadDocumentLoading}
      />
      <Drawer
        title="Add Documents"
        width={560}
        onClose={closeAddDrawer}
        open={open}
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
        extra={
          <Space>
            <Button onClick={closeAddDrawer}>Cancel</Button>
          </Space>
        }
      >
        <Space>
          <Button icon={<CameraOutlined />} onClick={openUploadModal}>
            Upload Document
          </Button>
          {file ? <FileViewer filepath={MEMBER_DOCUMENTS_BASE_PATH + file} /> : null}
        </Space>
        <Divider />
        <AddMembershipForm onSubmit={onSubmit} addDocumentLoading={addDocumentLoading} />
      </Drawer>
    </>
  );
};

export default AddMemberDocumentModal;
