import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '.'; // Import your axiosBaseQuery function

const baseQuery = axiosBaseQuery();

const alertsApi = createApi({
    reducerPath: 'alertsApi',
    baseQuery,
    tagTypes: ["ALERTS"],
    endpoints: (builder) => ({
        listAllAlerts: builder.query({
            query: ({ clubBranchId, alertCategory, date }) => ({
                url: '/alerts/list-by-alerts-category',
                method: 'GET',
                params: { clubBranchId, alertCategory, date }
            }),
            providesTags: (_result, _error, { clubBranchId, alertCategory, date }) => [
                { type: 'ALERTS', clubBranchId, alertCategory, date },
            ],
            transformResponse: (rawData: AlertsResponseFormat) => rawData.data,
        }),
    }),
});

export const {
    useListAllAlertsQuery,
} = alertsApi;

export const alertsApiReducer = alertsApi.reducer;
export const alertsApiMiddleware = alertsApi.middleware;
export const alertsReducerPath = alertsApi.reducerPath;

export const fetchAlerts = ({ clubBranchId, alertCategory, date }: { clubBranchId: number, alertCategory: string, date: Date }) =>
    alertsApi.endpoints.listAllAlerts.initiate({ clubBranchId, alertCategory, date });

interface AlertsResponseFormat {
    data: AlertsAttributes[];
    message: string;
    success: boolean;
}

export interface AlertsAttributes {
    alertId: number;
    clubId: number;
    clubBranchId: number;
    memberId: number;
    message: string;
    sentTo: string;
    alertType: string;
    alertCategory: string;
    serviceSuccess: boolean;
    serviceMessage: string;
    metadata: any;
    createdBy: number;
    updatedBy: number;
}

export default alertsApi;
