// src/Login.tsx
import React from "react";
import PageHeader from "../../../components/common/PageHeader";
import ListPackages from "../../../components/packages/ListPackages";
import { useSelector } from "react-redux";
import { RootState } from "../../../stores/rootReducers";
import { useNavigate } from "react-router-dom";
import { useEnableOrDisableMutation, useGetPackagesQuery } from "../../../stores/apiSlices/packagesApiSlice";
import ErrorComponent from "../../../components/common/ErrorComponent";
import BackdropSpinner from "../../../components/common/BackDrop";
import { PACKAGE_ADD, PACKAGE_UPDATE } from "../../../routes";

const PackageContainer: React.FC = () => {
  const { selectedBranch } = useSelector((state: RootState) => state?.user);
  const navigate = useNavigate();
  const { data, isError, error, isLoading, isFetching } = useGetPackagesQuery(selectedBranch.clubBranchId);

  const [enableOrDisable, { isLoading: enableDisableLoading }] = useEnableOrDisableMutation();

  const addNewPackage = () => {
    navigate(PACKAGE_ADD);
  };

  const onEditClick = (packageId: number) => {
    navigate(PACKAGE_UPDATE + packageId);
  };

  const onToggleActive = async (packageId: number, isActive: boolean) => {
    await enableOrDisable({ packageId, updatedPackage: { isActive } });
  };

  if (isError) {
    return <ErrorComponent error={error} />;
  }

  return (
    <>
      <BackdropSpinner visible={enableDisableLoading} />
      <PageHeader title="Membership Packages" displayButton={true} buttonText="Add" onButtonClick={addNewPackage} />
      <ListPackages
        data={data || []}
        loading={isLoading || isFetching}
        onViewClick={addNewPackage}
        onEditClick={onEditClick}
        onToggleActive={onToggleActive}
      />
    </>
  );
};

export default PackageContainer;
