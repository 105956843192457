import React from "react";
import PageHeader from "../../../components/common/PageHeader";
import { useNavigate, useParams } from "react-router-dom";
import { PACKAGES_LIST } from "../../../routes";
import AddEditPackageForm from "../../../components/packages/AddEditPackage";
import {
  PackageAttributes,
  useGetPackageDetailsQuery,
  useUpdatePackageMutation,
} from "../../../stores/apiSlices/packagesApiSlice";
import ErrorComponent from "../../../components/common/ErrorComponent";
import LoaderScreen from "../../../components/common/LoaderScreenComponent";

const PackageUpdateContainer: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { packageId } = params;

  const { data, isError, error, isLoading, isFetching } = useGetPackageDetailsQuery(packageId);

  const [updatePackage, { isLoading: updateLoading }] = useUpdatePackageMutation();

  console.log(data);

  if (isError) {
    return <ErrorComponent error={error} />;
  }

  if (isLoading || isFetching) return <LoaderScreen />;

  const onSubmit = async (updatedPackage: Partial<PackageAttributes>) => {
    // @ts-ignore
    const response = await updatePackage({ packageId, updatedPackage });
    // @ts-ignore
    if (response?.data?.success) navigate(PACKAGES_LIST);
  };

  return (
    <>
      <PageHeader title="Update Package" />
      <AddEditPackageForm onSubmit={onSubmit} loading={updateLoading} initialValues={data || {}} />
    </>
  );
};

export default PackageUpdateContainer;
