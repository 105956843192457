import React from "react";
import { ProTable, ProColumns } from "@ant-design/pro-table";
import { Space, Tag } from "antd";
import { AlertsAttributes } from "../../stores/apiSlices/alertsApiSlice";
import { extractDateNTime } from "../../utils/luxon";

interface Props {
  loading: boolean;
  fetching: boolean;
  data: AlertsAttributes[];
}

const ViewAlertsByDateComponent: React.FC<Props> = ({ loading, fetching, data }) => {
  const columns: ProColumns<AlertsAttributes>[] = [
    {
      title: "Sr No",
      valueType: "indexBorder",
    },
    {
      title: "Member Code",
      dataIndex: "memberCode",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_text: any, record: any) => `${record.firstName} ${record.lastName || ""}`,
      sorter: (a: any, b: any) => a.firstName.localeCompare(b.firstName), // Add a sorter for sorting by 'Name'
    },
    {
      title: "Message",
      dataIndex: "message",
    },
    {
      title: "Sent To",
      dataIndex: "sentTo",
    },
    {
      title: "Sent Response",
      dataIndex: "serviceMessage",
    },
    {
      title: "Sent By",
      dataIndex: "createdBy",
      render: (value) => (value && value !== "-" ? value : "SYSTEM"),
    },
    {
      title: "Alert Sent",
      dataIndex: "serviceSuccess",
      key: "serviceSuccess",
      render: (text: any) => <Space>{text ? <Tag color="green">Yes</Tag> : <Tag color="red">Failure</Tag>}</Space>,
      filters: [
        { text: "Yes", value: true },
        { text: "Failure", value: false },
      ],
      onFilter: (value: any, record: any) => record.membershipStatus === value,
    },
    {
      title: "Alert Type",
      dataIndex: "alertType",
      key: "alertType",
      render: (text: any) => (
        <Space>
          <Tag color="blue">{text}</Tag>
        </Space>
      ),
    },
    {
      title: "Created On",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text: any) => extractDateNTime(text),
    },
  ];

  return (
    <ProTable<AlertsAttributes>
      columns={columns}
      loading={loading || fetching}
      dataSource={data}
      rowKey="alertId"
      pagination={{ pageSize: 10 }}
      search={false}
      dateFormatter="string"
    />
  );
};

export default ViewAlertsByDateComponent;
