import React from "react";
import PageHeader from "../../../components/common/PageHeader";
import AddEditMemberForm from "../../../components/members/AddEditMemberForm";
import {
  MemberAttributes,
  useGetMemberDetailsQuery,
  useUpdateMemberMutation,
} from "../../../stores/apiSlices/membersApiSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../../stores/rootReducers";
import { useNavigate, useParams } from "react-router-dom";
import { MEMBERS_LIST_ALL } from "../../../routes";
import ErrorComponent from "../../../components/common/ErrorComponent";
import LoaderScreen from "../../../components/common/LoaderScreenComponent";

const MemberEditContainer: React.FC = () => {
  const params = useParams();
  const { memberCode } = params;

  const navigate = useNavigate();
  const { selectedBranch } = useSelector((state: RootState) => state?.user);

  const { data, isError, error, isLoading, isFetching } = useGetMemberDetailsQuery({
    clubBranchId: selectedBranch.clubBranchId,
    memberCode,
  });

  const [updateMember, { isLoading: updateLoading }] = useUpdateMemberMutation();

  const onSubmit = async (newData: Partial<MemberAttributes>) => {
    const response = await updateMember({ memberCode, clubBranchId: selectedBranch.clubBranchId, newData });
    // @ts-ignore
    if (response?.data?.success) navigate(MEMBERS_LIST_ALL);
  };

  if (isError) {
    return <ErrorComponent error={error} />;
  }

  if (isLoading || isFetching) return <LoaderScreen />;

  return (
    <>
      <PageHeader title="Edit Member" />
      <AddEditMemberForm onSubmit={onSubmit} loading={updateLoading} initialValues={data} />
    </>
  );
};

export default MemberEditContainer;
