import { Card, Col, Row } from "antd";
import React from "react";
import MembershipStatus from "./common/MembershipStatus";
import { calculateDaysRemaining, extractDate, extractDateNTime } from "../../utils/luxon";

interface MembershipCardProps {
  data: any;
}

interface MembershipCardTileProps {
  title: string;
  value: any;
}

const MembershipCardTile: React.FC<MembershipCardTileProps> = ({ title, value }) => {
  return (
    <>
      <Card type="inner" title={title} size="small">
        {value}
      </Card>
    </>
  );
};

const MembershipCard: React.FC<MembershipCardProps> = ({ data }) => {
  const {
    packageName = "",
    membershipId,
    isActive,
    durationInDays,
    startDate,
    endDate,
    createdByName,
    updatedByName,
    createdAt,
    updatedAt,
    igst,
    discount,
    finalPrice,
    paymentType,
  } = data;
  const gutter = 16;
  return (
    <Card
      style={{ width: "100%", marginBottom: 20 }}
      title={`${packageName} | ${
        isActive
          ? calculateDaysRemaining(endDate) + " days remaining"
          : "Inactive since " + calculateDaysRemaining(endDate) + " days"
      }.`}
      extra={<MembershipStatus isActive={isActive} />}
    >
      <Row gutter={gutter} align="middle">
        <Col xs={24} sm={24} md={12} lg={9} xl={6} style={{ marginBottom: gutter }}>
          <MembershipCardTile title="Membership ID" value={membershipId} />
        </Col>
        <Col xs={24} sm={24} md={12} lg={9} xl={6} style={{ marginBottom: gutter }}>
          <MembershipCardTile title="Duration in Days" value={durationInDays} />
        </Col>
        <Col xs={24} sm={24} md={12} lg={9} xl={6} style={{ marginBottom: gutter }}>
          <MembershipCardTile title="Membership Start Date" value={extractDate(startDate)} />
        </Col>
        <Col xs={24} sm={24} md={12} lg={9} xl={6} style={{ marginBottom: gutter }}>
          <MembershipCardTile title="Membership End Date" value={extractDate(endDate)} />
        </Col>

        <Col xs={24} sm={24} md={12} lg={9} xl={6} style={{ marginBottom: gutter }}>
          <MembershipCardTile title="GST" value={igst} />
        </Col>
        <Col xs={24} sm={24} md={12} lg={9} xl={6} style={{ marginBottom: gutter }}>
          <MembershipCardTile title="Discount" value={discount} />
        </Col>
        <Col xs={24} sm={24} md={12} lg={9} xl={6} style={{ marginBottom: gutter }}>
          <MembershipCardTile title="Amount Paid" value={finalPrice} />
        </Col>
        <Col xs={24} sm={24} md={12} lg={9} xl={6} style={{ marginBottom: gutter }}>
          <MembershipCardTile title="Payment Type" value={paymentType} />
        </Col>

        <Col xs={24} sm={24} md={12} lg={9} xl={6} style={{ marginBottom: gutter }}>
          <MembershipCardTile title="Created By" value={createdByName} />
        </Col>
        <Col xs={24} sm={24} md={12} lg={9} xl={6} style={{ marginBottom: gutter }}>
          <MembershipCardTile title="Updated By" value={updatedByName} />
        </Col>
        <Col xs={24} sm={24} md={12} lg={9} xl={6} style={{ marginBottom: gutter }}>
          <MembershipCardTile title="Created At" value={extractDateNTime(createdAt)} />
        </Col>
        <Col xs={24} sm={24} md={12} lg={9} xl={6} style={{ marginBottom: gutter }}>
          <MembershipCardTile title="Updated At" value={extractDateNTime(updatedAt)} />
        </Col>
      </Row>
    </Card>
  );
};

export default MembershipCard;
