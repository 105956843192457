import { Card } from "antd";
import React from "react";

interface CardInfoProps {
  title: string;
  value: any;
}

const CardInfoComponent: React.FC<CardInfoProps> = ({ title, value }) => {
  return (
    <>
      <Card type="inner" title={title} size="small">
        {value || "-"}
      </Card>
    </>
  );
};

export default CardInfoComponent;
