import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import axiosInstance from '../../utils/axios';
import { logOutUser, setUserToken } from '../../utils/cookies';

const reducerName = 'user';
const initialState = {
    isAuthenticated: false,
    userData: {
        firstName: "",
        lastName: "",
    },
    clubData: {
        name: "",
        logo: "",
        branches: [{ clubBranchId: null, name: '' }]
    },
    selectedBranch: {
        clubBranchId: null,
        name: ''
    },
    darkMode: false,
    systemDarkMode: true,
    compactTheme: false,
    themeColor: "#662D91",
    loading: false,
    error: "",
};

// Define an asynchronous thunk for handling login
export const loginUser = createAsyncThunk('user/loginUser', async (values) => {
    try {
        // Your API call logic here, for example using fetch or axios
        const { data } = await axiosInstance.post("/user/login", values); // Replace with your login API endpoint

        const { data: loginResponse } = data;

        setUserToken(loginResponse.token)
        return { userData: { ...loginResponse, token: undefined, club: undefined }, clubData: { ...loginResponse.club } }; // This data will be available in the fulfilled action payload
    } catch (error) {
        // Handle any error during the API call
        console.error('Error during login:', error);
        throw error; // This error will be available in the rejected action payload
    }
});

const userSlice = createSlice({
    name: reducerName,
    initialState,
    reducers: {
        resetLoginProfile: state => {
            logOutUser()
            state.userData = initialState.userData;
            state.clubData = initialState.clubData;
            state.selectedBranch = initialState.selectedBranch;
            state.loading = initialState.loading;
            state.error = initialState.error;
            state.isAuthenticated = initialState.isAuthenticated;
        },
        switchBranch: (state, action) => {
            const { key } = action.payload
            state.selectedBranch = state.clubData?.branches.find(branch => branch.clubBranchId + '' === key + '') || initialState.selectedBranch;
        },
        setDarkMode: (state, action) => {
            state.darkMode = !action.payload
        },
        setSystemDarkMode: (state, action) => {
            state.systemDarkMode = !action.payload
        },
        setCompactTheme: (state, action) => {
            state.compactTheme = !action.payload
        },
        setThemeColor: (state, action) => {
            state.themeColor = action.payload
        }
    },
    extraReducers: builder => {
        builder
            .addCase(loginUser.pending, state => {
                state.loading = true;
                state.error = "";
            })
            .addCase(loginUser.fulfilled, (state, action) => {
                const { userData, clubData } = action.payload
                state.userData = userData
                state.clubData = clubData
                state.selectedBranch = clubData.branches && clubData.branches[0] ? clubData.branches[0] : initialState.selectedBranch;
                state.loading = false;
                state.isAuthenticated = true;
            })
            .addCase(loginUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || "";
            });
    },
});

export const userActions = userSlice.actions;
export const userReducers = persistReducer(
    { key: reducerName, storage, whitelist: ['isAuthenticated', 'userData', 'clubData', 'selectedBranch', "darkMode", "systemDarkMode", "compactTheme", "themeColor"] },
    userSlice.reducer
);
