import { Result, Button } from "antd";
import React from "react";

const NoDataAvailable: React.FC = () => {
  return (
    <Result
      status="404"
      title="No Data Available"
      subTitle="Sorry, we couldn't find any data for you."
      extra={
        <Button type="primary" key="back" onClick={() => window.history.back()}>
          Go Back
        </Button>
      }
    />
  );
};

export default NoDataAvailable;
