import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '.';

const baseQuery = axiosBaseQuery();

const membersApi = createApi({
    reducerPath: 'membersApi',
    baseQuery,
    tagTypes: ["MEMBERS", "MEMBERSHIPS", "MEMBER_DETAIL", "MEMBERSHIP_DETAIL", "MEMBER_ACTIVITY", "MEMBER_DOCUMENTS"],
    endpoints: (builder) => ({
        getMembers: builder.query({
            query: (clubBranchId) => ({
                url: `/members?clubBranchId=${clubBranchId}`,
                method: 'GET',
            }),
            providesTags: ['MEMBERS'],
            transformResponse: (rawData: ResponseFormat) => {
                const { data } = rawData
                // Assuming the response structure is { data: { data: transformedData } }
                return data;
            },
        }),
        getMemberDetails: builder.query({
            query: ({ clubBranchId, memberCode }) => ({
                url: `/members/profile-by-member-code`,
                method: 'GET',
                params: { clubBranchId, memberCode }
            }),
            providesTags: (_result, _error, { clubBranchId, memberCode }) => [
                { type: 'MEMBER_DETAIL', clubBranchId, memberCode },
            ],
            transformResponse: (rawData: ResponseFormatSingle) => {
                const { data } = rawData
                // Assuming the response structure is { data: { data: transformedData } }
                return data;
            },
        }),
        getMemberships: builder.query({
            query: ({ clubBranchId, date, endDate }) => ({
                url: `/reports/sales`,
                method: 'GET',
                params: { clubBranchId, date, endDate }
            }),
            providesTags: (_result, _error, { clubBranchId, date, endDate }) => [
                { type: 'MEMBERSHIPS', clubBranchId, date, endDate },
            ],
            transformResponse: (rawData: MembershipResponseFormat) => {
                const { data } = rawData
                return data;
            },
        }),
        getMembershipDetails: builder.query({
            query: ({ clubBranchId, memberCode }) => ({
                url: `/members/view-membership`,
                method: 'GET',
                params: { clubBranchId, memberCode }
            }),
            providesTags: (_result, _error, { clubBranchId, memberCode }) => [
                { type: 'MEMBERSHIP_DETAIL', clubBranchId, memberCode },
            ],
            transformResponse: (rawData: ResponseFormat) => {
                const { data } = rawData
                // Assuming the response structure is { data: { data: transformedData } }
                return data;
            },
        }),
        getMemberActivity: builder.query({
            query: ({ clubBranchId, memberCode, startDate, endDate }) => ({
                url: `/attendance/list-by-member-code`,
                method: 'GET',
                params: { clubBranchId, memberCode, startDate, endDate }
            }),
            providesTags: (_result, _error, { clubBranchId, memberCode, startDate, endDate }) => [
                { type: 'MEMBER_ACTIVITY', clubBranchId, memberCode, startDate, endDate },
            ],
            transformResponse: (rawData: ResponseFormat) => {
                const { data } = rawData
                // Assuming the response structure is { data: { data: transformedData } }
                return data;
            },
        }),
        getMemberDocuments: builder.query({
            query: ({ clubBranchId, memberCode }) => ({
                url: `/members/documents/list`,
                method: 'GET',
                params: { clubBranchId, memberCode }
            }),
            providesTags: (_result, _error, { clubBranchId, memberCode }) => [
                { type: 'MEMBER_DOCUMENTS', clubBranchId, memberCode },
            ],
            transformResponse: (rawData: ResponseFormat) => {
                const { data } = rawData
                // Assuming the response structure is { data: { data: transformedData } }
                return data;
            },
        }),
        addMemberDocuments: builder.mutation({
            query: (newDocument) => ({
                url: '/members/documents/add',
                method: 'POST',
                data: newDocument,
            }),
            invalidatesTags: ({ clubBranchId, memberCode }) => [
                { type: 'MEMBER_DOCUMENTS', clubBranchId, memberCode },
            ],
        }),
        checkInMember: builder.mutation({
            query: (checkInPayload) => ({
                url: '/attendance/add',
                method: 'POST',
                data: checkInPayload,
            }),
            invalidatesTags: () => [
                'MEMBER_ACTIVITY'
            ],
        }),
        applyMembership: builder.mutation({
            query: (newMember) => ({
                url: '/members/apply-membership',
                method: 'POST',
                data: newMember,
            }),
            invalidatesTags: ({ clubBranchId, memberCode }) => [
                'MEMBERS',
                { type: 'MEMBER_DETAIL', clubBranchId, memberCode },
                { type: 'MEMBERSHIP_DETAIL', clubBranchId, memberCode },
            ],
        }),
        createMember: builder.mutation({
            query: (newMember) => ({
                url: '/members',
                method: 'POST',
                data: newMember,
            }),
            invalidatesTags: ["MEMBERS"]
        }),
        updateMember: builder.mutation({
            query: ({ memberCode, clubBranchId, newData }) => ({
                url: `/members/${memberCode}/${clubBranchId}`,
                method: 'PUT',
                data: newData,
            }),
            invalidatesTags: ({ memberCode, clubBranchId }) => [
                'MEMBERS',
                { type: 'MEMBER_DETAIL', clubBranchId, memberCode },
            ],
        }),
        updateMemberPhoto: builder.mutation({
            query: ({ memberCode, clubBranchId, newData }) => ({
                url: `/members/upload/member-profile-image/${memberCode}/${clubBranchId}`,
                method: 'PUT',
                data: newData,
            }),
            invalidatesTags: ({ memberCode, clubBranchId }) => [
                'MEMBERS',
                { type: 'MEMBER_DETAIL', clubBranchId, memberCode },
            ],
        }),
        uploadMemberDocument: builder.mutation({
            query: ({ newData }) => ({
                url: `/other/upload/member-document`,
                method: 'POST',
                data: newData,
            })
        }),
        deleteMember: builder.mutation({
            query: (memberId) => ({
                url: `/members/${memberId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ["MEMBERS"]
        }),
    }),
});

export const {
    useGetMembersQuery,
    useGetMemberDetailsQuery,
    useGetMembershipsQuery,
    useGetMembershipDetailsQuery,
    useGetMemberActivityQuery,
    useGetMemberDocumentsQuery,
    useAddMemberDocumentsMutation,
    useUploadMemberDocumentMutation,
    useCheckInMemberMutation,
    useApplyMembershipMutation,
    useCreateMemberMutation,
    useUpdateMemberMutation,
    useUpdateMemberPhotoMutation,
    useDeleteMemberMutation,
} = membersApi;

export const membersApiReducer = membersApi.reducer;
export const membersApiMiddleware = membersApi.middleware;
export const membersReducerPath = membersApi.reducerPath;

export const fetchMembers = (clubBranchId: number) =>
    membersApi.endpoints.getMembers.initiate({ clubBranchId });

export const createMember = (newMember: any) =>
    membersApi.endpoints.createMember.initiate(newMember);

export const updateMember = ({ userId, updatedMember }: { userId: number, updatedMember: any }) =>
    membersApi.endpoints.updateMember.initiate({ userId, updatedMember });

export const deleteMember = (userId: number) =>
    membersApi.endpoints.deleteMember.initiate(userId);

interface ResponseFormat {
    data: MemberAttributes[]
    message: string;
    success: boolean;
}

interface ResponseFormatSingle {
    data: MemberAttributes;
    message: string;
    success: boolean;
}

interface MembershipResponseFormat {
    data: MembershipAttributes[]
    message: string;
    success: boolean;
}

export interface MemberAttributes {
    memberId: number;
    memberCode: number;
    clubId: number;
    clubBranchId: number;
    firstName: string;
    lastName: string;
    gender: string;
    dob: Date;
    image: string;
    addressLine1: string;
    addressLine2: string;
    country: string;
    state: string;
    city: string;
    countryCode: string;
    phoneNumber: string;
    emergencyContact: string;
    mobile: string;
    membershipStatus: boolean;
    email: string;
    password: string;
    packageName: string;
    isActive: boolean;
    createdAt: Date;
    membershipEndDate: Date;
    updatedAt: Date;
    createdBy: number;
    updatedBy: number;
}

export interface MembershipAttributes {
    membershipId: number;
    memberId: number;
    memberCode: string;
    mobile: string;
    packageId: number;
    clubId: number;
    clubBranchId: number;
    startDate: string; // This is a date, but in string format
    endDate: string; // Same as above
    packageName: string;
    duration: string; // Duration in text format (could also be a number depending on context)
    durationInDays: number;
    discount: string; // Assuming monetary values as strings
    price: string;
    paymentType: string;
    totalPrice: string; // Final price after discount
    finalPrice: string; // Original price before discount
    cgst: string; // GST values, strings to maintain decimal formatting
    sgst: string;
    igst: string;
    isActive: boolean; // Boolean for status
    createdBy: number;
    updatedBy: number;
    createdAt: string; // Timestamps in ISO 8601 string format
    updatedAt: string;
    name: string; // Member's name
    image: string; // Image file name or path
    createdByName: string; // Names of who created or updated
    updatedByName: string;
}