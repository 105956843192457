import { useEffect, useState } from "react";
import { MembershipAttributes } from "../../stores/apiSlices/membersApiSlice";
import KpiComponent from "../common/KpiComponent";
import { countPaymentTypes, sumValues } from "../../utils/helper";

// Render the ProTable with the columns and data source
const SalesReportHeader: React.FC<{ data: MembershipAttributes[] }> = ({ data: membershipData }) => {
    const [kpiData, setKpiData] = useState<any>([])

    useEffect(() => {
        const tempKpiData = []

        // Set Total
        const totalMemberships = membershipData.length

        tempKpiData.push({
            title: 'Total Memberships',
            value: totalMemberships
        })

        // Set Total Collection
        const totalCollection = sumValues(membershipData, 'totalPrice')

        tempKpiData.push({
            title: 'Total Collection',
            value: totalCollection,
            prefix: '₹'
        })

        // Set Total Discount
        const totalDiscount = sumValues(membershipData, 'discount')

        tempKpiData.push({
            title: 'Total Discount',
            value: totalDiscount,
            prefix: '₹'
        })

        // Set Payment Types
        const paymentTypes = countPaymentTypes(membershipData)
        console.log(paymentTypes)

        for (const paymentType of paymentTypes) {
            tempKpiData.push(paymentType)
        }

        setKpiData(tempKpiData)

    }, [membershipData])
    return <KpiComponent data={kpiData} />
}

export default SalesReportHeader