import React, { useEffect, useState } from 'react';
import ProTable, { ProColumns } from '@ant-design/pro-table';
import { WhatsAppOutlined } from "@ant-design/icons";
import { MembershipAttributes } from '../../stores/apiSlices/membersApiSlice';
import { Button, Image, Space, Typography } from 'antd';
import { formatINR, handleOpenWhatsApp } from '../../utils/helper';
import { extractDate, extractDateNTime } from '../../utils/luxon';
import { MEMBER_IMAGES_BASE_PATH } from '../../utils/consants';


// Define the columns for the ProTable
const columns: ProColumns<MembershipAttributes>[] = [
    {
        title: 'Membership ID',
        dataIndex: 'membershipId',
        key: 'membershipId',
    },
    {
        title: "Member",
        dataIndex: "name",
        key: "name",
        render: (text: any, record: any) => (
            <Space align="center" size={8}>
                <Image
                    alt={text}
                    fallback="/assets/images/avatar.png"
                    src={MEMBER_IMAGES_BASE_PATH + record.image}
                    width={"80px"}
                    height={"80px"}
                    style={{ borderRadius: "50%" }}
                />
                <Typography.Text>{text}</Typography.Text>
            </Space>
        ),
        onFilter: (value: any, record: any) => record.name.toLowerCase().includes(value.toLowerCase()),
        sorter: (a: any, b: any) => a.name.localeCompare(b.name), // Add a sorter for sorting by 'Name'
    },
    {
        title: "Mobile",
        dataIndex: "mobile",
        key: "mobile",
        // render: (_text: any, record: any) => record.countryCode + "-" + record.phoneNumber,
        render: (_text: any, record: any) => (
            // <Space>{text === "Active" ? <Tag color="green">Active</Tag> : <Tag color="red">Inactive</Tag>}</Space>

            <Space direction="vertical" align="center" size={8}>
                <Typography.Text>
                    {record.mobile}
                </Typography.Text>
                <Button type="primary" icon={<WhatsAppOutlined />} onClick={() => handleOpenWhatsApp(record.mobile)}>
                    WhatsApp
                </Button>
            </Space>
        ),
    },
    {
        title: 'Start Date',
        dataIndex: 'startDate',
        key: 'startDate',
        render: (text: any) => extractDate(text),
    },
    {
        title: 'End Date',
        dataIndex: 'endDate',
        key: 'endDate',
        render: (text: any) => extractDate(text),
    },
    {
        title: 'Package Name',
        dataIndex: 'packageName',
        key: 'packageName',
    },
    {
        title: 'Rate',
        dataIndex: 'price',
        key: 'price',
        render: (text: any) => formatINR(text),
    },
    {
        title: 'Discount',
        dataIndex: 'discount',
        key: 'discount',
        render: (text: any) => formatINR(text),
    },
    {
        title: 'Paid Amount',
        dataIndex: 'totalPrice',
        key: 'totalPrice',
        render: (text: any) => formatINR(text),
    },
    {
        title: 'Payment Type',
        dataIndex: 'paymentType',
        key: 'paymentType',
    },
    {
        title: "Created On",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (text: any) => extractDateNTime(text),
    }
];

// Render the ProTable with the columns and data source
const MembershipTable: React.FC<{ data: MembershipAttributes[], loading: boolean }> = ({ data: membershipData, loading }) => {
    const [filteredData, setFilteredData] = useState(membershipData || []);
    useEffect(() => {
        if (membershipData && membershipData.length) {
            setFilteredData(membershipData);
        }
    }, [membershipData]);
    return (
        <ProTable<MembershipAttributes>
            columns={columns}
            dataSource={filteredData} // Use your data source
            rowKey="membershipId" // Unique key for each row
            search={false} // Disable search if not required
            loading={loading}
            toolbar={{
                search: {
                    placeholder: "Search Name, Mobile or Created/Updated by...",
                    onSearch: (value: string) => {
                        // Your search logic here
                        const searchResults =
                            membershipData && membershipData[0]
                                ? membershipData.filter(
                                    (member) =>
                                        String(member.membershipId).includes(value) ||
                                        member.name?.toLowerCase().includes(value.toLowerCase()) ||
                                        member.createdByName?.toLowerCase().includes(value.toLowerCase()) ||
                                        member.updatedByName?.toLowerCase().includes(value.toLowerCase()) ||
                                        member.mobile?.includes(value)
                                )
                                : [];
                        setFilteredData(searchResults);
                    },
                    onReset: () => {
                        console.log("reset clicked");
                        setFilteredData(membershipData || []);
                    },
                },
            }}
        />
    );
};

export default MembershipTable;
