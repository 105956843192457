import React from "react";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip, Legend } from "recharts";

interface PieChartData {
  totalMembers: number;
  joinedLastWeek: number;
  activeSubscription: number;
  inactiveSubscription: number;
}

interface PieChartProps {
  data: PieChartData;
}

const MemberPieChart: React.FC<PieChartProps> = ({ data }) => {
  const pieData = [
    { name: "Active Subscription", value: data.activeSubscription },
    { name: "Inactive Subscription", value: data.inactiveSubscription },
  ];

  const COLORS = ["#00C49F", "#B00020"];

  return (
    <ResponsiveContainer width="100%" height={300}>
      <PieChart>
        <Pie dataKey="value" data={pieData} cx="50%" cy="50%" outerRadius={80} fill="#8884d8" label>
          {pieData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip />
        <Legend />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default MemberPieChart;
