import React, { useState, useEffect } from "react";
import { Card, Typography } from "antd";
import { useDispatch } from "react-redux";
import { userActions } from "../../stores/slices/userSlice";

const { Title, Text } = Typography;

const LogoutUserContainer: React.FC = () => {
  const dispatch = useDispatch();
  const [timer, setTimer] = useState(2);

  useEffect(() => {
    const countdown = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    return () => clearInterval(countdown);
  }, []);

  useEffect(() => {
    if (timer === 0) {
      console.log("Logout success");
      dispatch(userActions.resetLoginProfile());
    }
  }, [timer, dispatch]);

  return (
    <Card
      style={{
        display: "flex",
        overflow: "hidden",
        justifyContent: "center",
        width: "100%",
        height: "100vh",
        borderRadius: 0,
        boxSizing: "border-box",
      }}
      headStyle={{ backgroundColor: "#E6ECF0" }}
      bodyStyle={{ padding: "5px" }}
      type="inner"
    >
      <div style={{ height: "100%", width: "100%" }}>
        <Title level={3}>Your session has expired</Title>
        <Text>Redirecting to login screen in {timer} seconds...</Text>
      </div>
    </Card>
  );
};

export default LogoutUserContainer;
