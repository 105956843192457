import React from "react";
import { Typography, Button } from "antd";

const { Title } = Typography;

interface PageHeaderProps {
  title: string;
  displayButton?: boolean;
  buttonText?: string;
  onButtonClick?: () => void;
}

const PageHeader: React.FC<PageHeaderProps> = ({ title = "", displayButton = false, buttonText, onButtonClick }) => {
  return (
    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
      <Title style={{ margin: 0 }}>{title}</Title>
      {displayButton ? (
        <Button type="primary" onClick={onButtonClick}>
          {buttonText}
        </Button>
      ) : null}
    </div>
  );
};

export default PageHeader;
