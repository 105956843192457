import React, { useState } from "react";
import { AutoComplete, Avatar, Input, List, Space, Typography } from "antd";
import type { SelectProps } from "antd";
import { useGetMembersQuery } from "../../stores/apiSlices/membersApiSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../stores/rootReducers";
import { Link } from "react-router-dom";
import { MEMBER_VIEW_DETAILS } from "../../routes";
import MembershipStatus from "../members/common/MembershipStatus";
import { MEMBER_IMAGES_BASE_PATH } from "../../utils/consants";

interface MemberTitleProps {
  firstName: string;
  lastName: string;
  membershipStatus: boolean;
}

const MemberTitle: React.FC<MemberTitleProps> = ({ firstName, lastName, membershipStatus }) => {
  return (
    <Space direction="horizontal">
      <Typography.Text>{firstName + " " + lastName}</Typography.Text>
      <MembershipStatus isActive={membershipStatus} />
    </Space>
  );
};

const GlobalSearchComponent: React.FC = () => {
  const { selectedBranch } = useSelector((state: RootState) => state?.user);
  const { data, isError, isLoading, isFetching } = useGetMembersQuery(selectedBranch.clubBranchId);

  const [options, setOptions] = useState<SelectProps<object>["options"]>([]);

  const handleSearch = (value: string) => {
    const searchResults =
      data && data[0]
        ? data.filter(
            (member) =>
              member.firstName?.toLowerCase().includes(value.toLowerCase()) ||
              member.lastName?.toLowerCase().includes(value.toLowerCase()) ||
              member.mobile?.includes(value) ||
              member.email?.toLowerCase().includes(value.toLowerCase())
          )
        : [];

    setOptions(
      searchResults.map((result, index) => ({
        value: "",
        label: (
          //   <div>
          //     <span>{`${result.firstName} ${result.lastName}`}</span>
          //     <br />
          //     <span>{`Mobile: ${result.mobile} | Email: ${result.email}`}</span>
          //   </div>
          <Link to={MEMBER_VIEW_DETAILS + result.memberCode}>
            <List itemLayout="horizontal">
              <List.Item>
                <List.Item.Meta
                  avatar={<Avatar src={result.image ? `${MEMBER_IMAGES_BASE_PATH}${result.image}` : "/assets/images/avatar.png"} />}
                  title={
                    <MemberTitle
                      firstName={result.firstName}
                      lastName={result.lastName || ""}
                      membershipStatus={result.membershipStatus}
                    />
                  }
                  description={`${result.countryCode}-${result.phoneNumber} | ${result.email || ""}`}
                />
              </List.Item>
            </List>
          </Link>
        ),
      }))
    );
  };

  //   const onSelect = (_value: string) => {
  //     setOptions([]);
  //     // Redirect to member detail page using React Router
  //     // history.push(`/member/${value}`);
  //   };

  return (
    <AutoComplete
      popupMatchSelectWidth={252}
      style={{ width: "40%" }}
      options={options}
      //   onSelect={onSelect}
      onSearch={handleSearch}
      disabled={isLoading || isFetching}
      size="large"
    >
      <Input.Search
        size="large"
        placeholder={isError ? "Something went wrong..." : "Search by Name, Number or Email..."}
        enterButton
      />
    </AutoComplete>
  );
};

export default GlobalSearchComponent;
