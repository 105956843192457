import { ENUM_PAYMENT_TYPE } from "./enums";

export const calculateAge = (birthdate: Date): number => {
    birthdate = new Date(birthdate)
    const currentDate = new Date();
    const birthYear = birthdate.getFullYear();
    const currentYear = currentDate.getFullYear();

    let age = currentYear - birthYear;

    // Adjust age if birthday hasn't occurred yet this year
    const birthMonth = birthdate.getMonth();
    const currentMonth = currentDate.getMonth();
    const birthDay = birthdate.getDate();
    const currentDay = currentDate.getDate();

    if (currentMonth < birthMonth || (currentMonth === birthMonth && currentDay < birthDay)) {
        age--;
    }

    return age;
}

export const handleOpenWhatsApp = (whatsappNumber: any) => {
    const whatsappWebURL = `https://web.whatsapp.com/send?phone=${whatsappNumber}`;

    window.open(whatsappWebURL, "_blank");
};

export const base64StringToBlob = (base64String: string, contentType: string = 'image/png'): Blob => {
    contentType = contentType ? contentType : 'image/png'
    const arrayBuffer = atob(base64String);
    const uint8Array = new Uint8Array(arrayBuffer.length);

    for (let i = 0; i < arrayBuffer.length; i++) {
        uint8Array[i] = arrayBuffer.charCodeAt(i);
    }

    return new Blob([uint8Array], { type: contentType });
}

// Function to format monetary values in INR
export const formatINR = (value: string | number) => {
    const formatter = new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'INR',
    });
    return formatter.format(parseFloat(String(value)));
};

/**
 * Sums all values for a given key in an array of objects.
 * @param array - The array of objects to process.
 * @param key - The key whose values should be summed.
 * @returns The sum of all values for the specified key.
 */
export const sumValues = (array: any[], key: string): number => {
    return array.reduce((acc, item) => {
        // Check if the item has the specified key
        if (key in item) {
            const value = item[key];
            let numberValue: number;

            // Convert the value to a number (handling different types)
            if (typeof value === 'string') {
                numberValue = parseFloat(value); // Handle string conversion
            } else if (typeof value === 'number') {
                numberValue = value; // Directly use the number
            } else {
                // If the value is not a number or a string, ignore it
                numberValue = 0;
            }

            // Add the converted number to the accumulator
            return acc + numberValue;
        }

        // If the key does not exist, continue without adding anything
        return acc;
    }, 0); // Start with an initial sum of 0
}

export const countPaymentTypes = (data: any[]): { title: string, value: number }[] => {
    // Create a map to keep track of the count for each payment type
    const paymentTypeCounts = new Map<ENUM_PAYMENT_TYPE, number>();

    // Initialize the map with all payment types set to 0
    Object.values(ENUM_PAYMENT_TYPE).forEach((type) => {
        paymentTypeCounts.set(type, 0);
    });

    // Loop through the data and count occurrences of each payment type
    data.forEach((item) => {
        const type = item.paymentType.toLowerCase(); // Ensure case-insensitive match

        if (paymentTypeCounts.has(type as ENUM_PAYMENT_TYPE)) {
            // Increment the count for the matching payment type
            paymentTypeCounts.set(
                type as ENUM_PAYMENT_TYPE,
                (paymentTypeCounts.get(type as ENUM_PAYMENT_TYPE) || 0) + 1
            );
        }
    });

    // Create the output in the desired format
    const result: { title: string, value: number }[] = [];

    paymentTypeCounts.forEach((value, key) => {
        result.push({ title: key.toUpperCase(), value }); // Convert title to uppercase
    });

    return result;
};