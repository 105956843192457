import { Divider, Image, Layout, Menu, theme } from "antd";
import {
  LaptopOutlined,
  UserOutlined,
  UserAddOutlined,
  UsergroupAddOutlined,
  LoginOutlined,
  NotificationOutlined,
  WechatOutlined,
  SettingOutlined,
  WhatsAppOutlined,
  FileOutlined,
  DollarOutlined
} from "@ant-design/icons";
import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

const { Sider, Header } = Layout;

const PrivateSider: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const onSiderClick = (item: any) => {
    navigate(item.key);
  };

  const pathnameArr = location.pathname.split("/");
  const defaultSelectedKeys = pathnameArr.length > 2 ? `/${pathnameArr[1]}/${pathnameArr[2]}` : `/${pathnameArr[1]}`;
  const defaultOpenKeys = pathnameArr.length > 2 ? `/${pathnameArr[1]}` : ``;

  return (
    <Sider breakpoint="lg" collapsedWidth="0" collapsible={true} style={{ background: colorBgContainer }}>
      <Link to={"https://www.clubone.fit"} target="_blank">
        <Header
          style={{
            background: "transparent", // Makes the background transparent
            padding: 0, // Removes padding from the header
          }}
        >
          <div
            style={{
              display: "flex", // Enables flex layout
              justifyContent: "center", // Centers horizontally
              alignItems: "center", // Centers vertically
            }}
          >
            <Image src="/club_one_logo_horizontal.png" preview={false} height={40} alt="club_one_logo" />
          </div>
        </Header>
      </Link>
      <Divider style={{ margin: 0 }} />
      <Menu
        mode="inline"
        defaultSelectedKeys={[defaultSelectedKeys]}
        defaultOpenKeys={[defaultOpenKeys]}
        style={{ height: "100%", borderRight: 0 }}
        onClick={onSiderClick}
        items={items}
      />
    </Sider>
  );
};

export default PrivateSider;

const items = [
  {
    key: "/dashboard",
    icon: <UserOutlined />,
    label: "Dashboard",
  },
  {
    key: "/members",
    icon: <LaptopOutlined />,
    label: "Members",
    children: [
      {
        key: "/members/add",
        icon: <UserAddOutlined />,
        label: "Add Member",
      },
      {
        key: "/members/list",
        icon: <UsergroupAddOutlined />,
        label: "List All",
      },
      {
        key: "/members/attendance",
        icon: <LoginOutlined />,
        label: "Attendance",
      },
    ],
  },
  {
    key: "/alerts",
    icon: <NotificationOutlined />,
    label: "Alerts",
    children: [
      {
        key: "/alerts/sms",
        icon: <WechatOutlined />,
        label: "SMS",
      },
    ],
  },
  {
    key: "/reports",
    icon: <FileOutlined />,
    label: "Reports",
    children: [
      {
        key: "/reports/sales",
        icon: <DollarOutlined />,
        label: "Sales",
      },
    ],
  },
  {
    key: "/config",
    icon: <SettingOutlined />,
    label: "Config",
    children: [
      {
        key: "/config/packages",
        icon: <UsergroupAddOutlined />,
        label: "Packages",
      },
      {
        key: "/config/whatsapp",
        icon: <WhatsAppOutlined />,
        label: "Whatsapp",
      },
    ],
  },
];
