import React, { useState } from "react"
import { useSelector } from "react-redux";
import { RootState } from "../../stores/rootReducers";
import { useGetMembershipsQuery } from "../../stores/apiSlices/membersApiSlice";
import ErrorComponent from "../../components/common/ErrorComponent";
import SalesReportTable from "../../components/reports/SalesReportTable";
import SalesReportHeader from "../../components/reports/SalesRepotHeader";
import SalesReportDatePicker from "../../components/reports/SalesReportDatePicker";
import { DateTime } from "luxon";

const SalesReportContainer: React.FC = () => {
    const [date, setDate] = useState(DateTime.local())
    const [endDate, setEndDate] = useState(DateTime.local())
    const { clubBranchId } = useSelector((state: RootState) => state?.user?.selectedBranch);
    const { data, isError, error, isLoading, isFetching } = useGetMembershipsQuery({ clubBranchId, date, endDate });

    if (isError) {
        return <ErrorComponent error={error} />;
    }

    return (
        <>
            <div style={{ paddingBottom: 10 }}>
                <SalesReportHeader data={data || []} />
            </div >
            <div style={{ paddingBottom: 10 }}>
                <SalesReportDatePicker date={date} setDate={setDate} endDate={endDate} setEndDate={setEndDate} />
            </div >
            <SalesReportTable data={data || []} loading={isLoading || isFetching} />
        </>
    )
}

export default SalesReportContainer