import React, { useState } from "react";
import { Card, Layout, Space, Image, Button, Typography, Row, Col } from "antd";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  CameraOutlined,
  InfoCircleOutlined,
  PhoneOutlined,
  MailOutlined,
  CalendarOutlined,
  EnvironmentOutlined,
  EditOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";
import GenderComponent from "../members/GenderComponent";
import AddOrEditMemberImageModal from "../members/AddOrEditMemberImage";
import { base64StringToBlob, calculateAge } from "../../utils/helper";
import { RootState } from "../../stores/rootReducers";
import { useGetMemberDetailsQuery, useUpdateMemberPhotoMutation } from "../../stores/apiSlices/membersApiSlice";
import ErrorComponent from "../common/ErrorComponent";
import LoaderScreen from "../common/LoaderScreenComponent";
import { useSelector } from "react-redux";
import { MEMBER_IMAGES_BASE_PATH } from "../../utils/consants";
import { extractDate } from "../../utils/luxon";
import { MEMBER_EDIT } from "../../routes";
import MembershipStatus from "../members/common/MembershipStatus";

const { Content } = Layout;

const items = [
  { key: "view-details", label: "Details" },
  { key: "view-membership", label: "Membership" },
  { key: "view-activity", label: "Activity" },
  { key: "view-documents", label: "Documents" },
];

const { Title, Text } = Typography;

interface MemberFieldsProps {
  icon: React.ReactNode;
  fieldName: string;
  fieldValue: string;
}

const MemberFields: React.FC<MemberFieldsProps> = ({ icon, fieldName, fieldValue }) => {
  return (
    <Space size={8}>
      <span>{icon}</span>
      <Text strong style={{ fontSize: "13px" }}>
        {fieldName}:
      </Text>
      <Text style={{ fontSize: "13px" }}>{fieldValue ? fieldValue : "-"}</Text>
    </Space>
  );
};

const MemberHeaderLayout: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const pathNameArr = location.pathname.split("/");

  const [modalVisible, setModalVisible] = useState(false);
  const { selectedBranch } = useSelector((state: RootState) => state?.user);
  const params = useParams();
  const { memberCode } = params;

  const {
    data: memberData,
    isError,
    error,
    isLoading,
    isFetching,
  }: any = useGetMemberDetailsQuery({
    clubBranchId: selectedBranch.clubBranchId,
    memberCode,
  });

  const [updateMemberPhoto, { isLoading: updateLoading }] = useUpdateMemberPhotoMutation();

  const handleOpenModal = () => {
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const onTabsClick = (item: any) => {
    navigate(`/members/list/${item}/${pathNameArr[4]}`);
  };

  const handleSubmitModal = async (imageBuffer: any, fileType: any) => {
    const formData = new FormData();
    imageBuffer = base64StringToBlob(imageBuffer, fileType);
    formData.append("file", imageBuffer);
    const response = await updateMemberPhoto({
      memberCode,
      clubBranchId: selectedBranch.clubBranchId,
      newData: formData,
    });
    //@ts-ignore
    if (response?.data?.success) setModalVisible(false);
  };

  const handleEditProfile = () => {
    // Handle the logic for editing the profile
    navigate(MEMBER_EDIT + memberCode);
  };

  const handleOpenWhatsApp = (whatsappNumber: any) => {
    const whatsappWebURL = `https://web.whatsapp.com/send?phone=${whatsappNumber}`;

    window.open(whatsappWebURL, "_blank");
  };

  if (isError) {
    return <ErrorComponent error={error} />;
  }

  console.log(memberData);
  if (isLoading || isFetching) return <LoaderScreen />;

  return (
    <>
      <AddOrEditMemberImageModal
        visible={modalVisible}
        onClose={handleCloseModal}
        onSubmit={handleSubmitModal}
        modalLoading={updateLoading}
      />
      <div style={{ height: "100vh" }}>
        <Row gutter={[16, 16]}>
          {/* Left side */}
          <Col xs={24} md={7}>
            <Card
              title={
                <Space direction="horizontal" align="center" size={5}>
                  <Title level={3} style={{ margin: 0 }}>{`${memberData.firstName}`}</Title>
                  {memberData.dob ? (
                    <Title level={4} style={{ margin: 0 }}>
                      ({calculateAge(memberData.dob)} yrs)
                    </Title>
                  ) : null}
                  <GenderComponent gender={memberData.gender} />
                </Space>
              }
              extra={
                <MembershipStatus
                  isActive={memberData.membershipStatus === "Active"}
                  endDate={memberData.membershipEndDate}
                />
              }
              style={{ padding: "10px" }}
            >
              <Space direction="vertical" align="start" size={8}>
                <Image
                  alt={memberData.firstName}
                  fallback="/assets/images/avatar.png"
                  src={MEMBER_IMAGES_BASE_PATH + memberData.image}
                  style={{ borderRadius: "5%", width: "100%" }}
                />
                <Space>
                  <Button icon={<CameraOutlined />} onClick={handleOpenModal}>
                    Image
                  </Button>
                  <Button type="primary" icon={<EditOutlined />} onClick={handleEditProfile}>
                    Edit
                  </Button>
                  <Button
                    type="primary"
                    icon={<WhatsAppOutlined />}
                    onClick={() => handleOpenWhatsApp(memberData.mobile)}
                  >
                    WhatsApp
                  </Button>
                </Space>
                <MemberFields
                  icon={<InfoCircleOutlined />}
                  fieldName="Member Code"
                  fieldValue={memberData.memberCode}
                />
                <MemberFields icon={<EnvironmentOutlined />} fieldName="Location" fieldValue={memberData.city} />
                <MemberFields icon={<PhoneOutlined />} fieldName="Phone" fieldValue={memberData.phoneNumber} />
                <MemberFields icon={<MailOutlined />} fieldName="Email" fieldValue={memberData.email} />
                <MemberFields icon={<CalendarOutlined />} fieldName="DOB" fieldValue={extractDate(memberData.dob)} />
                <MemberFields
                  icon={<CalendarOutlined />}
                  fieldName="Member Since"
                  fieldValue={extractDate(memberData.createdAt)}
                />

                {/* <QRCode
                  value={JSON.stringify({
                    memberCode: memberData.memberCode,
                    clubId: memberData.clubId,
                    clubBranchId: memberData.clubBranchId,
                  })}
                  style={{ width: "100%" }}
                /> */}
              </Space>
            </Card>
          </Col>
          {/* Right side */}
          <Col xs={24} md={17}>
            <Card
              style={{ width: "100%" }}
              // title="Member Details"
              // extra={<a href="#">More</a>}
              tabList={items}
              defaultActiveTabKey={pathNameArr[3]}
              onTabChange={onTabsClick}
            >
              <Content>
                <Outlet />
              </Content>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default MemberHeaderLayout;
