import React from "react";
import { Typography, Button, Space } from "antd";
import MyDatePicker from "../../common/DatePicker";

const { Title } = Typography;

interface PageHeaderProps {
  title: string;
  onButtonClick?: () => void;
  selectedDate: any;
  onDateChange?: (date: any, dateString: string | string[]) => void; // Adjusted type signature
}

const SmsPageHeader: React.FC<PageHeaderProps> = ({ title = "", selectedDate, onButtonClick, onDateChange }) => {
  return (
    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
      <Title style={{ margin: 0 }}>{title}</Title>
      <Space>
        <Button type="primary" style={{ marginLeft: 10 }} onClick={onButtonClick}>
          Send Bulk SMS
        </Button>
        <MyDatePicker value={selectedDate} onChange={onDateChange} />
      </Space>
    </div>
  );
};

export default SmsPageHeader;
