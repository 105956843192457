import React from "react";
import { Row, Col, Card } from "antd";

import { extractDate, extractDateNTime } from "../../utils/luxon";
import CardInfoComponent from "../common/CardInfo";

interface CommonProps {
  memberData: any;
}

const PersonalInformation: React.FC<CommonProps> = ({ memberData }) => {
  const gutter = 16;
  return (
    <Card
      style={{ width: "100%", marginBottom: 20 }}
      title={`Personal Information`}
      // extra={<MembershipStatus isActive={isActive} />}
    >
      <Row gutter={gutter} align="middle">
        <Col xs={24} sm={24} md={12} lg={8} xl={6} style={{ marginBottom: gutter }}>
          <CardInfoComponent
            title="Full Name"
            value={`${memberData.firstName} ${memberData.middleName || ""} ${memberData.lastName || ""}`}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={6} style={{ marginBottom: gutter }}>
          <CardInfoComponent title="DOB" value={extractDate(memberData.dob)} />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={6} style={{ marginBottom: gutter }}>
          <CardInfoComponent
            title="Alternate number"
            value={`${memberData.alternateCountryCode || ""} - ${memberData.alternateNumber || ""}`}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={6} style={{ marginBottom: gutter }}>
          <CardInfoComponent title="Member Since" value={extractDateNTime(memberData.createdAt)} />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={6} style={{ marginBottom: gutter }}>
          <CardInfoComponent title="Aadhar" value={memberData.aadhaar} />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={6} style={{ marginBottom: gutter }}>
          <CardInfoComponent title="Pan" value={memberData.pan} />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={6} style={{ marginBottom: gutter }}>
          <CardInfoComponent title="Company Name" value={memberData.companyName} />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={6} style={{ marginBottom: gutter }}>
          <CardInfoComponent title="GST" value={memberData.gstNumber} />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={6} style={{ marginBottom: gutter }}>
          <CardInfoComponent title="Last Updated" value={extractDateNTime(memberData.updatedAt)} />{" "}
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={6} style={{ marginBottom: gutter }}>
          <CardInfoComponent title="Added By" value={memberData.createdByName} />{" "}
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={6} style={{ marginBottom: gutter }}>
          <CardInfoComponent title="Updated By" value={memberData.updatedByName} />{" "}
        </Col>
      </Row>
    </Card>
  );
};

const AddressInformation: React.FC<CommonProps> = ({ memberData }) => {
  const gutter = 16;
  return (
    <Card
      style={{ width: "100%", marginBottom: 20 }}
      title={`Address Information`}
      // extra={<MembershipStatus isActive={isActive} />}
    >
      <Row gutter={gutter} align="middle">
        <Col xs={24} sm={24} md={12} lg={8} xl={6} style={{ marginBottom: gutter }}>
          <CardInfoComponent title="Country" value={memberData.country} />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={6} style={{ marginBottom: gutter }}>
          <CardInfoComponent title="State" value={memberData.state} />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={6} style={{ marginBottom: gutter }}>
          <CardInfoComponent title="City" value={memberData.city} />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={6} style={{ marginBottom: gutter }}>
          <CardInfoComponent title="Line 1" value={memberData.addressLine1} />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={6} style={{ marginBottom: gutter }}>
          <CardInfoComponent title="Line 2" value={memberData.addressLine2} />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={6} style={{ marginBottom: gutter }}>
          <CardInfoComponent title="Pincode" value={memberData.pincode} />
        </Col>
      </Row>
    </Card>
  );
};

const EContactInformation: React.FC<CommonProps> = ({ memberData }) => {
  const gutter = 16;
  return (
    <Card
      style={{ width: "100%", marginBottom: 20 }}
      title={`Emergency Contact Information`}
      // extra={<MembershipStatus isActive={isActive} />}
    >
      <Row gutter={gutter} align="middle">
        <Col xs={24} sm={24} md={12} lg={8} xl={6} style={{ marginBottom: gutter }}>
          <CardInfoComponent title="Name" value={`${memberData.eFirstName} ${memberData.eLastName}`} />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={6} style={{ marginBottom: gutter }}>
          <CardInfoComponent title="Email" value={memberData.eEmail} />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={6} style={{ marginBottom: gutter }}>
          <CardInfoComponent title="Phone Number" value={memberData.ePhoneNumber} />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={6} style={{ marginBottom: gutter }}>
          <CardInfoComponent title="Relation" value={memberData.eRelationship} />
        </Col>
      </Row>
    </Card>
  );
};

const MemberDetails: React.FC<CommonProps> = ({ memberData }) => {
  return (
    <>
      <PersonalInformation memberData={memberData} />
      <AddressInformation memberData={memberData} />
      <EContactInformation memberData={memberData} />
    </>
  );
};

export default MemberDetails;
