// axiosConfig.ts
import axios, { AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { message } from 'antd'; // Import Ant Design message component
import { API_BASE_URL } from './consants';
import { getUserToken } from './cookies';

const axiosInstance: AxiosInstance = axios.create({
    baseURL: API_BASE_URL,
    timeout: 60000, // Adjust the timeout as needed
});

// Request interceptor
axiosInstance.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
        // You can modify the request config here, such as adding headers or handling tokens
        const accessToken = getUserToken();

        if (accessToken) {
            config.headers['Authorization'] = `Bearer ${accessToken}`;
        }
        return config;
    },
    (error) => {
        // Handle request errors
        return Promise.reject(error);
    }
);

// Response interceptor
axiosInstance.interceptors.response.use(
    (response: AxiosResponse) => {
        // You can modify the response here, such as handling errors or extracting data
        if (response) {
            // The request was made, but the server responded with an error
            const { data } = response;

            if (data && data.message) {
                // Show the server-provided error message
                message.success(data.message);
            } else {
                // Show a generic error message
                message.error('An error occurred while processing your request.');
            }
        } else {
            // The request was not made or the response was not received
            message.error('Network error. Please check your internet connection.');
        }

        return response;
    },
    (error) => {
        if (error?.response?.data?.message) {
            message.error(error?.response?.data?.message)
        } else {
            message.error(error.message);
        }
        // Handle response errors
        return Promise.reject(error);
    }
);

export default axiosInstance;
