// src/Login.tsx
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../stores/rootReducers";
import { useListAllAlertsQuery } from "../../../stores/apiSlices/alertsApiSlice";
import ErrorComponent from "../../../components/common/ErrorComponent";
import ViewSmsByDateComponent from "../../../components/alerts/ViewAlertsByDate";
import SmsPageHeader from "../../../components/alerts/sms/SmsPageHeader";
import { DateTime } from "luxon";

const SmsContainer: React.FC = () => {
  const [selectedDate, setSelectedDate] = useState(DateTime.local());
  const { selectedBranch } = useSelector((state: RootState) => state?.user);
  const {
    data: alertsData,
    isError,
    error,
    isLoading,
    isFetching,
  } = useListAllAlertsQuery({
    clubBranchId: selectedBranch.clubBranchId,
    date: selectedDate,
    alertCategory: "sms",
  });

  const onDateChange = (value: any) => {
    if (value) setSelectedDate(value);
  };

  if (isError) return <ErrorComponent error={error} />;

  return (
    <div>
      <SmsPageHeader title="Sent SMS" selectedDate={selectedDate} onDateChange={onDateChange} />
      <ViewSmsByDateComponent data={alertsData || []} loading={isLoading} fetching={isFetching} />
    </div>
  );
};

export default SmsContainer;
