import React from "react";
import { Space, Tag, Typography } from "antd";
import { calculateDaysRemaining } from "../../../utils/luxon";

const MembershipStatus: React.FC<{ isActive: boolean; endDate?: any }> = ({ isActive, endDate = undefined }) => {
  return (
    <Space direction="vertical" align="start">
      {isActive ? <Tag color="green">Active</Tag> : <Tag color="red">Inactive</Tag>}
      {endDate ? (
        isActive ? (
          <Typography.Text>{`${calculateDaysRemaining(endDate)} days Left`}</Typography.Text>
        ) : (
          <Typography.Text>{`Since ${calculateDaysRemaining(endDate)} days`}</Typography.Text>
        )
      ) : null}
    </Space>
  );
};

export default MembershipStatus;
