import { Tag } from "antd";
import React from "react";

export enum GENDER {
  MALE = "M",
  FEMALE = "F",
}

interface GenderComponentProps {
  gender: GENDER;
}

const GenderComponent: React.FC<GenderComponentProps> = ({ gender }) => {
  const getGenderTag = () => {
    switch (gender) {
      case GENDER.MALE:
        return <Tag color="blue">Male</Tag>;
      case GENDER.FEMALE:
        return <Tag color="pink">Female</Tag>;
      default:
        return <Tag>Unknown</Tag>;
    }
  };

  return getGenderTag();
};

export default GenderComponent;
