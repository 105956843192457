import React from "react";
import PageHeader from "../../../components/common/PageHeader";
import { useSelector } from "react-redux";
import { RootState } from "../../../stores/rootReducers";
import { useNavigate } from "react-router-dom";
import { PACKAGES_LIST } from "../../../routes";
import AddEditPackageForm from "../../../components/packages/AddEditPackage";
import { PackageAttributes, useCreatePackageMutation } from "../../../stores/apiSlices/packagesApiSlice";

const PackageAddContainer: React.FC = () => {
  const navigate = useNavigate();
  const { selectedBranch } = useSelector((state: RootState) => state?.user);
  const [createPackage, { isLoading: addLoading }] = useCreatePackageMutation();

  const onSubmit = async (values: Partial<PackageAttributes>) => {
    // @ts-ignore
    values.clubBranchId = selectedBranch.clubBranchId;
    const response = await createPackage(values);
    // @ts-ignore
    if (response?.data?.success) navigate(PACKAGES_LIST);
  };

  return (
    <>
      <PageHeader title="Add Package" />
      <AddEditPackageForm onSubmit={onSubmit} loading={addLoading} initialValues={{}} />
    </>
  );
};

export default PackageAddContainer;
