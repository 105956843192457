import React from "react";
import ProForm, { ProFormDatePicker, ProFormRadio, ProFormSelect, ProFormText } from "@ant-design/pro-form";
import { Card, FormInstance, Space } from "antd";
import { Col, Row } from "antd";
import countryCodes from "../../utils/countryCodes";

interface AddOrUpdateFormProps {
  onSubmit: any;
  loading: boolean;
  initialValues?: any;
}

const AddEditMemberForm: React.FC<AddOrUpdateFormProps> = ({ onSubmit, loading, initialValues = {} }) => {
  const formRef = React.createRef<FormInstance>();

  const layout = {
    lg: 8,
    md: 12,
    sm: 24,
  };

  return (
    <ProForm
      formRef={formRef}
      onFinish={onSubmit}
      loading={loading}
      initialValues={{
        gender: "M", // Assuming default gender is Male
        ...initialValues,
      }}
    >
      <Space direction="vertical" size={20}>
        <Card title="Personal Information">
          <Row gutter={16}>
            <Col {...layout}>
              <ProFormText
                name="firstName"
                label="First Name"
                placeholder="Enter first name"
                rules={[{ required: true, message: "Please enter first name" }]}
              />
            </Col>
            <Col {...layout}>
              <ProFormText
                name="middleName"
                label="Middle Name"
                placeholder="Enter first name"
                rules={[{ required: false, message: "Please enter middle name" }]}
              />
            </Col>
            <Col {...layout}>
              <ProFormText
                name="lastName"
                label="Last Name"
                placeholder="Enter last name"
                rules={[{ required: false, message: "Please enter last name" }]}
              />
            </Col>
            <Col {...layout}>
              <ProFormSelect
                name="countryCode"
                label="Country Code"
                placeholder="Select country code"
                rules={[{ required: true, message: "Please select country code" }]}
                options={countryCodes}
              />
            </Col>
            <Col {...layout}>
              <ProFormText
                name="phoneNumber"
                label="Phone Number"
                placeholder="Enter phone number"
                rules={[{ required: true, message: "Please enter phone number" }]}
              />
            </Col>
            <Col {...layout}>
              <ProFormText
                name="email"
                label="Email"
                placeholder="Enter email"
                rules={[{ required: false, message: "Please enter email" }]}
              />
            </Col>
            <Col {...layout}>
              <ProFormSelect
                name="alternateCountryCode"
                label="Alternate Country Code"
                placeholder="Select country code"
                rules={[{ required: false, message: "Please select country code" }]}
                options={countryCodes}
              />
            </Col>
            <Col {...layout}>
              <ProFormText
                name="alternateNumber"
                label="Alternate Phone Number"
                placeholder="Enter phone number"
                rules={[{ required: false, message: "Please enter phone number" }]}
              />
            </Col>
            <Col {...layout}>
              <ProFormText
                name="memberCode"
                label="Member ID"
                placeholder="Enter Member Code/ID"
                rules={[{ required: true, message: "Please enter Member Code/ID" }]}
              />
            </Col>

            <Col {...layout}>
              <ProFormRadio.Group
                name="gender"
                label="Gender"
                rules={[{ required: true, message: "Please select gender" }]}
                options={[
                  { label: "Male", value: "M" },
                  { label: "Female", value: "F" },
                  { label: "Other", value: "O" },
                ]}
              />
            </Col>
            <Col {...layout}>
              <ProFormDatePicker
                name="dob"
                label="Date of Birth"
                placeholder="Select date of birth"
                rules={[{ required: true, message: "Please select date of birth" }]}
              />
            </Col>
            <Col {...layout}>
              <ProFormText
                name="aadhaar"
                label="Aadhar Number"
                placeholder="Enter Aadhaar Number"
                rules={[{ required: false, message: "Please enter Aadhaar Number" }]}
              />
            </Col>
            <Col {...layout}>
              <ProFormText
                name="pan"
                label="Pan Number"
                placeholder="Enter Pan Number"
                rules={[{ required: false, message: "Please enter Pan Number" }]}
              />
            </Col>
            <Col {...layout}>
              <ProFormText
                name="gstNumber"
                label="GST Number"
                placeholder="Enter GST Number"
                rules={[{ required: false, message: "Please enter GST Number" }]}
              />
            </Col>
            <Col {...layout}>
              <ProFormText
                name="companyName"
                label="Company Name"
                placeholder="Enter Company Name"
                rules={[{ required: false, message: "Please enter Company Name" }]}
              />
            </Col>
          </Row>
        </Card>
        <Card title="Member Address">
          <Row gutter={16}>
            <Col {...layout}>
              <ProFormText
                name="addressLine1"
                label="Address Line 1"
                placeholder="Enter address line 1"
                rules={[{ required: false, message: "Please enter address line 1" }]}
              />
            </Col>
            <Col {...layout}>
              <ProFormText name="addressLine2" label="Address Line 2" placeholder="Enter address line 2" />
            </Col>
            <Col {...layout}>
              <ProFormText
                name="country"
                label="Country"
                placeholder="Enter country"
                rules={[{ required: false, message: "Please enter country" }]}
              />
            </Col>
            <Col {...layout}>
              <ProFormText
                name="state"
                label="State"
                placeholder="Enter state"
                rules={[{ required: false, message: "Please enter state" }]}
              />
            </Col>
            <Col {...layout}>
              <ProFormText
                name="city"
                label="City"
                placeholder="Enter city"
                rules={[{ required: false, message: "Please enter city" }]}
              />
            </Col>
            <Col {...layout}>
              <ProFormText
                name="pincode"
                label="Pin Code"
                placeholder="Enter Pin Code Number"
                rules={[{ required: false, message: "Please enter Pin Code Number" }]}
              />
            </Col>
          </Row>
        </Card>
        <Card title="Emergency Contact">
          <Row gutter={16}>
            <Col {...layout}>
              <ProFormText
                name="eFirstName"
                label="First Name"
                placeholder="Enter First Name"
                rules={[{ required: false, message: "Please enter First Name" }]}
              />
            </Col>
            <Col {...layout}>
              <ProFormText
                name="eLastName"
                label="Last Name"
                placeholder="Enter Last Name"
                rules={[{ required: false, message: "Please enter Last Name" }]}
              />
            </Col>
            <Col {...layout}>
              <ProFormText
                name="eEmail"
                label="Email Address"
                placeholder="Enter Email Address"
                rules={[{ required: false, message: "Please enter Email Address" }]}
              />
            </Col>
            <Col {...layout}>
              <ProFormText
                name="ePhoneNumber"
                label="PhoneNumber"
                placeholder="Enter Phone Number"
                rules={[{ required: false, message: "Please enter Phone Number" }]}
              />
            </Col>
            <Col {...layout}>
              <ProFormText
                name="eRelationship"
                label="Relationship"
                placeholder="Enter Relationship"
                rules={[{ required: false, message: "Please enter Relationship" }]}
              />
            </Col>
          </Row>
        </Card>
      </Space>
    </ProForm>
  );
};

export default AddEditMemberForm;
