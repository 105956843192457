// src/Login.tsx
import React from "react";
import { Form, Input, Button, Checkbox, Card, Typography, Image, Space } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import ColorChangingTitle from "../common/ColorChangingTitle";

const { Title, Link } = Typography;

const Header: React.FC = () => {
  return (
    <Space style={{ padding: 10 }} direction="vertical">
      <Image src="/club_one_logo_rect.png" preview={false} width={200} alt="club_one_logo" />
      <Link href="www.clubone.fit">www.clubone.fit</Link>
      <Title style={{ margin: 0 }} level={3}>
        Login
      </Title>
    </Space>
  );
};

const LoginForm: React.FC<{ onSubmit: (values: any) => Promise<void>; loading: boolean }> = ({ onSubmit, loading }) => {
  return (
    <Card title={<Header />} headStyle={{ textAlign: "center" }}>
      <Form name="login_form" initialValues={{ remember: true }} onFinish={onSubmit}>
        <Form.Item name="email" rules={[{ required: true, message: "Please input your Email!" }]}>
          <Input prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />} placeholder="example@example.com" />
        </Form.Item>
        <Form.Item name="password" rules={[{ required: true, message: "Please input your Password!" }]}>
          <Input
            prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item>
          <Form.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox>Remember me</Checkbox>
          </Form.Item>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading} style={{ width: "100%" }}>
            Log in
          </Button>
          <Link href="#">Forgot password?</Link>
        </Form.Item>
      </Form>
      <ColorChangingTitle content="Sales & Support: +91-9769-11-9696" />
    </Card>
  );
};

export default LoginForm;
