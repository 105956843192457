import React from "react";
import { Form, Button, Row } from "antd";
import MyDatePicker from "./DatePicker";

interface DateRangePickerFormProps {
  onSubmit: (selectedRange: any) => void;
  onCheckInClick: () => void;
  loading: boolean;
}

const { RangePicker } = MyDatePicker;
const DateRangePickerForm: React.FC<DateRangePickerFormProps> = ({ onSubmit, onCheckInClick, loading }) => {
  const gutter = 16;
  return (
    <Form name="dateRangeForm" onFinish={onSubmit}>
      <Row gutter={gutter} align="middle" justify="space-between">
        <Form.Item
          name="dateRange"
          label="Date Range"
          rules={[{ required: true, message: "Please select a date range" }]}
        >
          <RangePicker />
          <Button style={{ marginLeft: 15 }} type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
        <Form.Item>
          <Button type="primary" onClick={onCheckInClick} loading={loading}>
            CheckIn User
          </Button>
        </Form.Item>
      </Row>
    </Form>
  );
};

export default DateRangePickerForm;
