export enum ENUM_PAYMENT_TYPE {
    CASH = "cash",
    UPI = 'upi',
    GPAY = 'gpay',
    PHONEPE = 'phonepe',
    PAYTM = 'paytm',
    CARD = 'card',
    OTHER = 'other'
}

export enum ENUM_DOCUMENT_TYPE {
    AADHAR = "aadhar",
    PAN = 'pan-card',
    DRIVING_LICENSE = 'driving-license',
    OTHER = 'other'
}

export enum ENUM_GENDER {
    MALE = "M",
    FEMALE = 'F',
    OTHER = 'O'
}