import { configureStore } from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';

import rootReducers from './rootReducers';
import { membersApiMiddleware } from './apiSlices/membersApiSlice';
import { packagesApiMiddleware } from './apiSlices/packagesApiSlice';
import { alertsApiMiddleware } from './apiSlices/alertsApiSlice';

export const store = configureStore({
    reducer: rootReducers,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }).concat([
            membersApiMiddleware,
            packagesApiMiddleware,
            alertsApiMiddleware
        ]),
});

export const persistor = persistStore(store);
