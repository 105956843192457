import React from "react";
import { Button, Drawer, Form, Select, Space, Radio, Input } from "antd";
import { ENUM_PAYMENT_TYPE } from "../../utils/enums";
import { useGetPackagesQuery } from "../../stores/apiSlices/packagesApiSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../stores/rootReducers";
import ErrorComponent from "../common/ErrorComponent";
import MyDatePicker from "../common/DatePicker";

const { Option } = Select;

interface FormData {
  packageId: number;
  paymentType: string;
  startDate: string;
}

interface YourFormProps {
  onSubmit: (formData: FormData) => void;
  applyMembershipLoading: boolean;
}

interface AddMembershipProps {
  open: boolean;
  applyMembershipLoading: boolean;
  closeAddDrawer: any;
  onFormSubmit: any;
}

const AddMembershipForm: React.FC<YourFormProps> = ({ onSubmit, applyMembershipLoading }) => {
  const { selectedBranch } = useSelector((state: RootState) => state?.user);
  const { data, isError, error, isLoading, isFetching } = useGetPackagesQuery(selectedBranch.clubBranchId);

  const onFinish = (values: FormData) => {
    onSubmit(values);
  };

  if (isError) {
    return <ErrorComponent error={error} />;
  }

  return (
    <Form initialValues={{ discount: 0 }} onFinish={onFinish} layout="vertical">
      <Form.Item
        label="Select Package"
        name="packageId"
        rules={[{ required: true, message: "Please select a package" }]}
      >
        <Select placeholder="Select a package" loading={isLoading || isFetching}>
          {data && data[0]
            ? data.map((packageData) => (
                <Option disabled={!packageData.isActive} key={packageData.packageId} value={packageData.packageId}>
                  {`${packageData.packageName} - ${packageData.duration} - ${packageData.finalPrice}`}
                </Option>
              ))
            : null}
        </Select>
      </Form.Item>

      <Form.Item
        label="Payment Type"
        name="paymentType"
        rules={[{ required: true, message: "Please select a payment type" }]}
      >
        <Radio.Group>
          {Object.values(ENUM_PAYMENT_TYPE).map((paymentType) => (
            <Radio key={paymentType} value={paymentType}>
              {paymentType}
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>

      <Form.Item
        label="Start Date"
        name="startDate"
        rules={[{ required: true, message: "Please select a start date" }]}
      >
        <MyDatePicker style={{ width: "100%" }} format="YYYY-MM-DD" />
      </Form.Item>

      <Form.Item label="Discount" name="discount" rules={[{ required: true, message: "Please Enter discount" }]}>
        <Input type="number" style={{ width: "100%" }} />
      </Form.Item>

      <Form.Item>
        <Button loading={applyMembershipLoading} type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

const AddMembershipModal: React.FC<AddMembershipProps> = ({
  open,
  closeAddDrawer,
  onFormSubmit,
  applyMembershipLoading,
}) => {
  return (
    <>
      <Drawer
        title="Apply a Membership"
        width={560}
        onClose={closeAddDrawer}
        open={open}
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
        extra={
          <Space>
            <Button onClick={closeAddDrawer}>Cancel</Button>
          </Space>
        }
      >
        <AddMembershipForm onSubmit={onFormSubmit} applyMembershipLoading={applyMembershipLoading} />
      </Drawer>
    </>
  );
};

export default AddMembershipModal;
