import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '.'; // Import your axiosBaseQuery function

const baseQuery = axiosBaseQuery();

const packagesApi = createApi({
    reducerPath: 'packagesApi',
    baseQuery,
    tagTypes: ["PACKAGES"],
    endpoints: (builder) => ({
        getPackages: builder.query({
            query: (clubBranchId) => ({
                url: '/packages/list-all',
                method: 'GET',
                params: { clubBranchId }
            }),
            providesTags: ['PACKAGES'],
            transformResponse: (rawData: PackageResponseFormat) => rawData.data,
        }),
        getPackageDetails: builder.query({
            query: (packageId) => ({
                url: `/packages/${packageId}`,
                method: 'GET',
            }),
            providesTags: (_result, _error, packageId) => [
                { type: 'PACKAGES', packageId },
            ],
            transformResponse: (rawData: PackageResponseFormat) => rawData.data,
        }),
        createPackage: builder.mutation({
            query: (newPackage) => ({
                url: '/packages/add-new',
                method: 'POST',
                data: newPackage,
            }),
            invalidatesTags: ["PACKAGES"],
        }),
        updatePackage: builder.mutation({
            query: ({ packageId, updatedPackage }) => ({
                url: `/packages/${packageId}`,
                method: 'PUT',
                data: updatedPackage,
            }),
            invalidatesTags: ["PACKAGES"],
        }),
        enableOrDisable: builder.mutation({
            query: ({ packageId, updatedPackage }) => ({
                url: `/packages/enable-or-disable/${packageId}`,
                method: 'PUT',
                data: updatedPackage
            }),
            invalidatesTags: ["PACKAGES"],
        }),
    }),
});

export const {
    useGetPackagesQuery,
    useGetPackageDetailsQuery,
    useCreatePackageMutation,
    useUpdatePackageMutation,
    useEnableOrDisableMutation,
} = packagesApi;

export const packagesApiReducer = packagesApi.reducer;
export const packagesApiMiddleware = packagesApi.middleware;
export const packagesReducerPath = packagesApi.reducerPath;

export const fetchPackages = (clubBranchId: number) =>
    packagesApi.endpoints.getPackages.initiate({ clubBranchId });

export const createPackage = (newPackage: any) =>
    packagesApi.endpoints.createPackage.initiate(newPackage);

export const updatePackage = ({ packageId, updatedPackage }: { packageId: number, updatedPackage: any }) =>
    packagesApi.endpoints.updatePackage.initiate({ packageId, updatedPackage });

export const enableOrDisable = ({ packageId, updatedPackage }: { packageId: number, updatedPackage: any }) =>
    packagesApi.endpoints.enableOrDisable.initiate({ packageId, updatedPackage });

interface PackageResponseFormat {
    data: PackageAttributes[];
    message: string;
    success: boolean;
}

export interface PackageAttributes {
    packageId: number;
    clubId: number;
    clubBranchId: number;
    packageName: string;
    description: string;
    duration: string;
    durationInDays: number;
    price: number;
    finalPrice: number;
    cgst: number;
    sgst: number;
    igst: number;
    isActive: boolean;
    createdBy: number;
    updatedBy: number;
}

export default packagesApi;
