import { DateTime } from 'luxon';

export const extractDateNTime = (inputDate: string | Date): string => {
    // Convert the input to a JavaScript Date object
    const myDate = new Date(inputDate);

    // Check if the conversion to Date was successful
    if (isNaN(myDate.getTime())) {
        return 'Invalid Date';
    }

    // Convert the Date object to a Luxon DateTime object
    const luxonDate = DateTime.fromJSDate(myDate);

    // Format the Luxon DateTime object in the desired format with ordinal indicator for the day
    const formattedDate = luxonDate.toFormat("d' 'LLL',' yyyy '-' h:mm a");

    // Return the formatted date
    return formattedDate;
};

export const extractDate = (inputDate: string | Date): string => {
    // Convert the input to a JavaScript Date object
    const myDate = new Date(inputDate);

    // Check if the conversion to Date was successful
    if (isNaN(myDate.getTime())) {
        return 'Invalid Date';
    }

    // Convert the Date object to a Luxon DateTime object
    const luxonDate = DateTime.fromJSDate(myDate);

    // Format the Luxon DateTime object in the desired format with ordinal indicator for the day
    const formattedDate = luxonDate.toFormat("d' 'LLL',' yyyy");

    // Return the formatted date
    return formattedDate;
};

export const extractTime = (inputDate: string | Date): string => {
    // Convert the input to a JavaScript Date object
    const myDate = new Date(inputDate);

    // Check if the conversion to Date was successful
    if (isNaN(myDate.getTime())) {
        return 'Invalid Date';
    }

    // Convert the Date object to a Luxon DateTime object
    const luxonDate = DateTime.fromJSDate(myDate);

    // Format the Luxon DateTime object in the desired format with ordinal indicator for the day
    const formattedDate = luxonDate.toFormat("h:mm a");

    // Return the formatted date
    return formattedDate;
};

export const calculateDaysRemaining = (targetDate: string | Date): number | string => {
    // Convert the input to a Luxon DateTime object
    const targetDateTime = DateTime.fromJSDate(new Date(targetDate));

    // Check if the conversion to DateTime was successful
    if (!targetDateTime.isValid) {
        return 'Invalid Date';
    }

    // Get the current date and time
    const currentDate = DateTime.now();

    // Calculate the difference between the target date and the current date
    const duration = targetDateTime.diff(currentDate);

    // Extract the number of days from the duration
    const daysRemaining = Math.floor(duration.as('days'));

    // Return the number of days remaining
    return daysRemaining;
};

export const getCurrentDateNTime = (): Date => {
    return DateTime.local().toJSDate();
}

export const getCurrentDate = (): string => {
    return DateTime.local().toFormat('yyyy-MM-dd');
}

export const isDateWithinLast7Days = (date: Date | string): boolean => {
    // Get the current date
    const currentDate = DateTime.local();

    // Parse the input date into a Luxon DateTime object
    const inputDate = DateTime.fromJSDate(typeof date === 'string' ? new Date(date) : date);

    // Get the date 7 days ago from the current date
    const sevenDaysAgo = currentDate.minus({ days: 7 });

    // Check if the input date is between seven days ago and today
    return inputDate >= sevenDaysAgo && inputDate <= currentDate;
}

export const isDateWithinInputDays = (date: Date | string, days: number): boolean => {
    // Get the current date
    const currentDate = DateTime.local();

    // Parse the input date into a Luxon DateTime object
    const inputDate = DateTime.fromJSDate(typeof date === 'string' ? new Date(date) : date);

    // Get the date 7 days ago from the current date
    const sevenDaysAgo = currentDate.minus({ days });

    // Check if the input date is between seven days ago and today
    return inputDate >= sevenDaysAgo && inputDate <= currentDate;
}